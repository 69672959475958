import {
  IconCoin,
  IconPix,
  IconClock,
} from '@libs/atlas/components/icons/default';

import { IconGift, COLOR_NEUTRAL_00, IconStar } from '@consumidor-positivo/aurora';

import Message from '@libs/atlas/components/Message';
import Conditional from '@libs/atlas/components/misc/Conditional';

import iconStar from '@libs/atlas/assets/icons/star.svg';

const Banner = ({
  debt,
  showCountdown,
  countdownTimer,
  isShowMaxNumberInstallment,
  partnerHasSpecialOffer,
  isRedirectPartner,
}: any) => {
  const maxNumberInstallment = debt?.maxNumberInstallment;
  const paymentWithPix = false; //TODO: adicionar validação para verificar se o usuário possui a possibilidade de fechar o acordo com PIX

  const getInstallmentText = () => {
    if (maxNumberInstallment > 1) {
      return paymentWithPix
        ? `Ou até ${maxNumberInstallment} vezes`
        : `Pague em até ${maxNumberInstallment}x`;
    }

    return `Pague à vista`;
  };

  return (
    <div className="debit-card__banner">
      <div className="debit-card__banner-holder">
        <div className="debit-card__banner-group">
          <Conditional
            condition={partnerHasSpecialOffer}
            renderIf={
              <>
                <Message
                  type="custom"
                  customClass="payment-information special-offer"
                  icon={<IconGift rawColor={COLOR_NEUTRAL_00} />}
                >
                  {/* TODO: adjust message according to partner offer */}
                  Oferta exclusiva
                </Message>
                <img
                  className="debit-card__star debit-card__star--one"
                  src={iconStar}
                  alt="Ícone de estrela"
                />
                <img
                  className="debit-card__star debit-card__star--two"
                  src={iconStar}
                  alt="Ícone de estrela"
                />
                <img
                  className="debit-card__star debit-card__star--three"
                  src={iconStar}
                  alt="Ícone de estrela"
                />
              </>
            }
            renderElse={
              <>
                <Conditional
                  condition={isShowMaxNumberInstallment}
                  renderIf={
                    <Conditional
                      condition={paymentWithPix && !showCountdown}
                      renderIf={
                        <>
                          <Message
                            type="custom"
                            customClass="payment-information pix"
                            icon={<IconPix />}
                          >
                            Pague com Pix
                          </Message>

                          <Message
                            type="custom"
                            customClass="payment-information pix"
                          >
                            {getInstallmentText()}
                          </Message>
                        </>
                      }
                      renderElse={
                        <Conditional
                          condition={isRedirectPartner}
                          renderIf={
                            <Message
                              type="custom"
                              customClass="payment-information"
                              icon={<IconStar />}
                            >
                              Melhores propostas
                            </Message>
                          }
                          renderElse={
                            <Message
                              type="custom"
                              customClass="payment-information"
                              icon={<IconCoin />}
                            >
                              {getInstallmentText()}
                            </Message>
                          }
                        />
                      }
                    />
                  }
                />
                <Conditional
                  condition={showCountdown}
                  renderIf={
                    <Message
                      type="custom"
                      customClass="payment-information countdown"
                    >
                      <IconClock />
                      {!isShowMaxNumberInstallment && 'Desconto expira em '}
                      <strong>{countdownTimer}</strong>
                    </Message>
                  }
                />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
