import classNames from 'classnames';

import './styles.scss';

interface SkeletonProps {
  size?: "sm" | "md" | "lg";
  type?: "logo" | "button" | "text";
}

const Skeleton = ({ size, type }: SkeletonProps) => {

  return (
    <div
      className={classNames("debit-card__skeleton", {
        "debit-card__skeleton--sm": size === "sm",
        "debit-card__skeleton--md": size === "md",
        "debit-card__skeleton--lg": size === "lg",
        "debit-card__skeleton--logo": type === "logo",
        "debit-card__skeleton--button": type === "button",
        "debit-card__skeleton--text": type === "text",
      })}
    />
  );
};

export default Skeleton;
