import { IconHelpCircle } from '../icons/default';

import './styles.scss';

type DebitCardNegativedFlagProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const DebitCardNegativedFlag = ({ onClick }: DebitCardNegativedFlagProps) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <a
      className="debit-card__flag text-brand-ocean-base"
      aria-label="button"
      onClick={handleClick}
    >
      Dívida negativada <IconHelpCircle />
    </a>
  );
};

export default DebitCardNegativedFlag;
