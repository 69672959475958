import { Router } from '@common/routes/Router';
import { useRedline } from '@libs/redline';
import { getAnonymousID } from 'redline-client-sdk';
import {
  addCookie,
  getCookie,
  getCookiesStartsWith,
} from '@libs/utils/helpers/getSafeCookies';
import { isProd } from '@libs/utils/helpers/isProd';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@libs/atlas/styles/GlobalStyles.scss';
import 'react-spinetic/styles.css';
import { useUserStore } from '@common/store/useUserStore';
import useHotjar from '@libs/utils/hooks/useHotjar';
import ErrorBoundary from '@common/components/ErrorBoundary';
import untilLoad from '@libs/utils/helpers/untilLoad';

const appVersion: string = import.meta.env?.VITE_DEPLOY_VERSION || "";

function App() {
  const { track } = useRedline();
  const anonymousId = getAnonymousID();
  const user = useUserStore((state) => state.user);
  const hotjar = useHotjar();
  const [sentForcedSessionStarted, setSendForcedSessionStarted] = useState(false);

  function sendEventExperimentsDogx() {
    const dxCookies = getCookiesStartsWith('dx-');
    const { 'dx-bg': removedProp, ...dxCookiesOnly } = dxCookies;
    const hasTest = Object.keys(dxCookiesOnly).length;

    if (hasTest) {
      const experiments: string[] = [];
      const experimentsResults: string[] = [];

      const dxExperiments = Object.keys(dxCookiesOnly)
        .map((name) => {
          const dxValues = dxCookiesOnly[name]?.split(':');
          if (!dxValues) return null;

          const [variant, version, percentage] = dxValues;

          experiments.push(name);
          experimentsResults.push(variant);

          return {
            name,
            variant,
            version: Number(version),
            percentage: Number(percentage),
          };
        })
        .filter((experiment) => experiment !== null);

      track.dogx.experimentsLoaded({
        experiments: dxExperiments,
      });

      hotjar.add({
        ...(!!user.customerIdHash && { customerIdHash: user.customerIdHash }),
        anonymousId: anonymousId,

        sessionExperiments: experiments.join('|').slice(0, 199),
        sessionVariants: experimentsResults.join('|').slice(0, 199),
        appVersion
      });
    } else {
      hotjar.add({
        ...(!!user.customerIdHash && { customerIdHash: user.customerIdHash }),
        anonymousId: anonymousId,
        appVersion
      });
    }
  }

  useEffect(() => {
    forceSessionStarted();
  }, []);

  async function forceSessionStarted() {
    await untilLoad(() => window?.cpRedline?.ready, 7000);
    if (!sentForcedSessionStarted) {
      window?.cpRedline?.triggerSessionStarted();
      setSendForcedSessionStarted(true);
    }
  }

  useEffect(() => {
    const isAtendeFlow =
      getCookie('ATENDE_TOKEN') || getCookie('ATENDE_SIGNUP');

    if (isAtendeFlow) {
      const bodyHtml = document.querySelector('body');
      bodyHtml?.classList.add('atende-flow');
      bodyHtml?.style?.setProperty('overflow', 'auto');
    }
  }, []);

  useEffect(() => {
    const startSourceUrl = getCookie('start_source_url');
    if (!startSourceUrl) {
      addCookie({
        key: 'start_source_url',
        value: window.location.href,
      });
    }

    sendEventExperimentsDogx();
  }, []);

  useEffect(() => {
    hotjar.updateLoggedUser(user?.customerIdHash);
  }, [user]);

  useEffect(() => {
    if (isProd()) {

      window.__APPVERSION__ = appVersion;

      addCookie({
        key: 'appVersion',
        value: appVersion,
      });
    }
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
