import { pageNames } from '@common/routes/pageNames';
import { useUserStore } from '@common/store/useUserStore';
import {
  IconCreditCard,
  IconHelpCircle,
  IconLayers,
  IconNegotiation,
  IconUser,
  IconVelocimeter
} from '@consumidor-positivo/aurora';
import isDesktop from '@libs/utils/helpers/isDesktop';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePrivatePagesStates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const { addUser: addUserStore } = useUserStore();
  const [reminders, setReminders] = useState<Record<string, any>[]>([]);
  const [remindersLength, setRemindersLength] = useState(0);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

  const dataSourceNavbar = [
    {
      name: 'Início',
      onClick: () => navigate(pageNames.myDebts.path),
      active: location.pathname === pageNames.myDebts.path,
    },
    {
      name: 'Meus Acordos',
      onClick: () => navigate(pageNames.myAgreements.path),
      active: location.pathname === pageNames.myAgreements.path,
    },
    {
      name: 'Minhas Ofertas',
      onClick: () => navigate(pageNames.offers.path),
      active: location.pathname === pageNames.offers.path,
    },
    {
      name: 'Monitorar CPF',
      onClick: () => navigate(pageNames.myScore.path),
      active:
        location.pathname === pageNames.myScore.path ||
        location.pathname === pageNames.scoreKbaQuestions.path,
    },
    {
      name: 'Central de Ajuda',
      onClick: () => window.open('https://ajuda.acordocerto.com.br/'),
      active: false,
    },
  ];

  const dataSourceNavbarVertical = [
    {
      name: 'Inicio',
      onClick: () => navigate(pageNames.myDebts.path),
      active: location.pathname === pageNames.myDebts.path,
      Icon: <IconNegotiation />,
    },
    {
      name: 'Meus Acordos',
      onClick: () => navigate(pageNames.myAgreements.path),
      active: location.pathname === pageNames.myAgreements.path,
      Icon: <IconLayers />,
    },
    {
      name: 'Minhas Ofertas',
      onClick: () => navigate(pageNames.offers.path),
      active: location.pathname === pageNames.offers.path,
      Icon: <IconCreditCard />,
    },
    {
      name: 'Monitorar CPF',
      onClick: () => navigate(pageNames.myScore.path),
      active:
        location.pathname === pageNames.myScore.path ||
        location.pathname === pageNames.scoreKbaQuestions.path,
      Icon: <IconVelocimeter />,
    },
    {
      name: 'Minha Conta',
      onClick: () => navigate(pageNames.account.path),
      Icon: <IconUser />,
      active: location.pathname === pageNames.account.path,
    },
    {
      name: 'Central de Ajuda',
      onClick: () => window.open('https://ajuda.acordocerto.com.br/'),
      active: false,
      Icon: <IconHelpCircle />,
    },
  ].filter((item) => (isDesktop() ? item.name.includes('Minha Conta') : item));

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    reminders,
    setReminders,
    remindersLength,
    setRemindersLength,
    addUserStore,
    user,
    location,
    showLogoutModal,
    setShowLogoutModal,
  };
};
