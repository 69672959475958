import classNames from 'classnames';

type DebitCardTextProps = {
  size?: 'small' | 'medium' | 'large';
  type?: 'stripe';
  value: string | number;
  weigth?: 'bold';
  verticalAlign?: 'bottom';
};

const DebitCardText = ({
  size = 'small',
  type,
  value,
  weigth,
  verticalAlign,
}: DebitCardTextProps) => {
  const classText = classNames({
    [`debit-card__text-${size}`]: !!size,
    [`debit-card__text-${weigth}`]: !!weigth,
    [`debit-card__text-${type}`]: !!type,
    [`debit-card__text-${verticalAlign}`]: !!verticalAlign,
  });
  return <span className={classText} dangerouslySetInnerHTML={{ __html: value }} />
};

export default DebitCardText;
