import Conditional from '@libs/atlas/components/misc/Conditional';
import DebitCard from '@libs/atlas/components/DebitCard';
import { dateToLocale } from '@libs/utils/helpers/dateToLocale';

import { DebtData } from '@common/hooks/useDebts/types';
import constants from '@common/constants';
import { ClosedAgreementProps } from '../../ClosedAgreement/types';

import './styles.scss';

interface ConfirmAgreementValues {
  debtData: DebtData;
  path?: 'confirmacao' | 'acordo-fechado';
  firstInstallmentDate: string;
}

const ConfirmAgreementValues = ({
  debtData,
  path = 'confirmacao',
  firstInstallmentDate
}: ConfirmAgreementValues) => {
  return (
    <section className="confirm-agreement-values">
      <Conditional
        condition={path === 'confirmacao'}
        renderIf={
          <>
            <DebitCard.PriceInstallments>
              <Conditional
                condition={debtData.paymentOpt.isSinglePayment}
                renderIf={<DebitCard.Text value="Pague à vista" />}
              />

              <Conditional
                condition={debtData.paymentOpt.hasPreviousValue}
                renderIf={
                  <DebitCard.PriceLine>
                    <DebitCard.Text value="De" />
                    <DebitCard.Text
                      type="stripe"
                      weigth="bold"
                      value={debtData.paymentOpt.display.currentDebtValue}
                    />
                    <DebitCard.Text value="por" />
                  </DebitCard.PriceLine>
                }
              />

              <Conditional
                condition={debtData.paymentOpt.hasEntry}
                renderIf={
                  <DebitCard.Text
                    value={`Entrada de <strong>${debtData.paymentOpt.display.entryValue}<strong/>`}
                  />
                }
              />

              <DebitCard.PriceLine>
                <Conditional
                  condition={debtData.paymentOpt.hasEntry}
                  renderIf={<DebitCard.Text value="+" />}
                />

                <Conditional
                  condition={!debtData.paymentOpt.isSinglePayment}
                  renderIf={
                    <DebitCard.Text
                      value={`${debtData.paymentOpt.display.installments.number}x de`}
                    />
                  }
                />

                <DebitCard.Text
                  size="large"
                  weigth="bold"
                  value={
                    debtData.paymentOpt.display.installments.value.wholePart
                  }
                />
                <DebitCard.Text
                  size="medium"
                  weigth="bold"
                  value={`,${debtData.paymentOpt.display.installments.value.decimalPart}`}
                />
              </DebitCard.PriceLine>
            </DebitCard.PriceInstallments>
            <Conditional
              condition={debtData.paymentOpt.hasDicountVisible}
              renderIf={
                <DebitCard.PriceDiscount
                  title={`${debtData.paymentOpt.display.discount.label} de`}
                  value={debtData.paymentOpt.display.discount.percentage}
                />
              }
            />
          </>
        }
        renderElse={
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Conditional
                condition={debtData.paymentOpt.isSinglePayment}
                renderIf={<DebitCard.Text value={`À vista`} />}
                renderElse={
                  <DebitCard.Text
                    value={`Parcela 1 de ${debtData.paymentOpt.qtyInstallment}`}
                  />
                }
              />

              <DebitCard.Text
                size="large"
                weigth="bold"
                value={debtData.paymentOpt.display.entryValue}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DebitCard.Text value={`Vencimento`} />
              <DebitCard.Text value={firstInstallmentDate} weigth="bold" />
            </div>
          </>
        }
      />
    </section>
  );
};

export default ConfirmAgreementValues;
