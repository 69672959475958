import { ChangeEvent, RefObject, KeyboardEvent, forwardRef, LegacyRef } from 'react';
import classNames from 'classnames';
import Conditional from '../../misc/Conditional';
import './styles.scss';

interface InputProps {
  inputMode?: "text" | "search" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;
  autoComplete?: string;
  autoFocus?: boolean
  disabled?: boolean;
  errors: string[];
  htmlType: string;
  icon?: React.ReactNode;
  label?: string;
  name?: string;
  obs?: string;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  optional?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: string;
  value?: string | number;
  maxLength?: number;
  readOnly?: boolean;
  onClick?: () => void
}

const InputField = forwardRef(function({
  inputMode = 'text',
  autoComplete = 'off',
  autoFocus = true,
  disabled = false,
  errors,
  htmlType,
  icon: Icon,
  label,
  name,
  obs,
  onBlur,
  onChange,
  onKeyDown,
  placeholder,
  value,
  maxLength,
  readOnly,
  onClick,
  ...props
}: InputProps, ref) {
  const filteredError = errors.filter(err => err !== '-')
  const hasError = filteredError && !!filteredError?.length;
  const inputClassName = classNames('ac-input-field', {
    'ac-input-field--disabled': !!disabled,
    'ac-input-field--error': hasError,
    'ac-input-field--icon': !!Icon,
  });

  return (
    <div className={inputClassName}>
      <Conditional
        condition={hasError}
        renderIf={
          <div className="ac-input-field__error">
            {filteredError?.map((m) => `${m} `)}
          </div>
        }
      />
      <div className="ac-input-field__fieldset">
        <input
          onClick={onClick}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className="ac-input-field__input"
          disabled={disabled}
          inputMode={inputMode}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder || label}
          ref={ref as LegacyRef<HTMLInputElement> | undefined}
          type={htmlType}
          value={value}
          readOnly={readOnly}
          {...props}
        />
        <Conditional
          condition={!!label}
          renderIf={
            <label htmlFor={name} className="ac-input-field__label">
              {label}
            </label>
          }
        />
        <Conditional condition={!!Icon} renderIf={Icon} />
      </div>
      <Conditional
        condition={!!obs}
        renderIf={<div className="ac-input-field__obs">{obs}</div>}
      />
    </div>
  );
});

export default InputField;
