import { useNavigate } from 'react-router-dom';
import {
  COLOR_INFO_50,
  COLOR_WARNING_50,
  IconAlertTriangle,
  IconClock,
} from '@consumidor-positivo/aurora';
import Button from '@libs/atlas/components/Button';
import Report from '@libs/atlas/components/Report';
import Conditional from '@libs/atlas/components/misc/Conditional';
import Message from '@libs/atlas/components/Message';
import Text from '@libs/atlas/components/Text';
import { pageNames } from '@common/routes/pageNames';
import DebitAccount from './components/DebitAccount';
import Pix from './components/Pix';
import Billet from './components/Billet';
import { PaymentSectionProps } from './types';
import LazyImage from '@libs/atlas/components/LazyImage';
import PaymentSectionSkeleton from '@common/components/PaymentSectionSkeleton';
import EmailInfo from '../EmailInfo';
import BradescoLatePaymentMsg from '@dues/components/BradescoLatePaymentMsg';
import * as bradesco from '@dues/helpers/bradesco';

import './styles.scss';

const PaymentSection = ({
  debtData,
  closedAgreement,

  billet,
  hasBillet,

  paymentStatus,
  qrCodeUrl,
  isPixExternal,
  pixCode,

  handleCopyPixCode,
  isLoadingTryAgain,
  isPixLoading,
  isPixSuccess,

  pixText,
  isCodeCopied,
  statusLabels,
  handleGeneratePixCode,
  showSkeleton,
  isLoadingBillet,
}: PaymentSectionProps) => {
  const isPending = paymentStatus === 'pending';

  const message = {
    error: `<center><p> Parece que ocorreu um erro com o pagamento via Pix. Pedimos desculpas,
          mas não se preocupe, o valor <b>já foi devolvido</b> para sua conta.</p></br>
      <p>Por favor, efetue o pagamento via boleto. </p></center>`,
    pixPaid: `<center><p>Recebemos o seu pagamento!<strong> Agora seu pagamento está sendo analisado pelo nosso time,
       </strong> nessa etapa você ainda pode encontrar a dívida disponível junto a ${closedAgreement?.debt?.partner?.name},
        mas fique tranquilo.</p></br>

      <p>Em <strong>até 2 dias úteis</strong> vamos finalizar a análise do seu pagamento, e a partir dai o
       ${closedAgreement?.debt?.partner?.name} vai ter até 7 dias úteis para remover a dívida e quaisquer restrições no seu
        nome junto aos orgãos de proteção ao crédito.</p></center>
     `,
  };

  const navigate = useNavigate();

  const { flowWithPix, singlePaymentMethod, paymentOption } = closedAgreement || {};

  const showPixSection =
    (!!flowWithPix && !singlePaymentMethod) ||
    (!!singlePaymentMethod && paymentOption.paymentMethodName === 'PIX');

  const shouldHaveBilletPayment =
    !singlePaymentMethod || paymentOption.paymentMethodName === 'BILLET';

  const bradescoDisclaimerValue =
    bradesco.getPaymentOptiontOverdueInstallmentValue(paymentOption);

  const isBilletUnavailable =
    !!shouldHaveBilletPayment &&
    !isLoadingBillet &&
    !hasBillet &&
    paymentStatus === 'pending';

  const unavailableBilletLabel = {
    text: 'Boleto disponível em 1 dia útil',
    type: 'info',
    icon: (
      <div className="payment-section__unavailable-icon">
        <IconClock rawColor={COLOR_INFO_50} />
      </div>
    ),
  } as const;

  const isBradesco = debtData.currentPartner.isBradesco;
  const { hasEntryForDebitInAcc } = debtData?.paymentMeth || {};

  const shouldUseUnavailableStatusLabel =
    (isBilletUnavailable && !isBradesco) ||
    (isBilletUnavailable && isBradesco && hasEntryForDebitInAcc);

  const usedStatusLabel = shouldUseUnavailableStatusLabel
    ? unavailableBilletLabel
    : statusLabels[paymentStatus];

  const shouldShowEmailBilletMessage = isBilletUnavailable && !isBradesco;

  return (
    <div className="payment-section">
      <Conditional
        condition={showSkeleton}
        renderIf={<PaymentSectionSkeleton />}
        renderElse={
          <>
            <Report.Root type={usedStatusLabel.type} border="less">
              <Report.Text>{usedStatusLabel.text}</Report.Text>
              <Report.Icon>{usedStatusLabel.icon}</Report.Icon>
            </Report.Root>
            <Conditional
              condition={shouldShowEmailBilletMessage}
              renderIf={
                <EmailInfo
                  flowWithPix={closedAgreement?.flowWithPix}
                  debtData={debtData}
                />
              }
            />

            <Conditional
              condition={isPending}
              renderIf={
                <>
                  <Conditional
                    condition={!!qrCodeUrl && !!closedAgreement?.flowWithPix}
                    renderIf={
                      <div className="payment-section__qr-code">
                        <div>
                          <Text as="h3" className="payment-section__subheading">
                            Pix QR Code
                          </Text>
                          <Text
                            as="p"
                            weight="regular"
                            className="payment-section__text-code"
                          >
                            Leia o QR code ao lado ou copie o código Pix abaixo
                            e cole em seu app do banco para finalizar o
                            pagamento.
                          </Text>
                        </div>
                        <LazyImage
                          className="payment-section__qr-code--img"
                          src={qrCodeUrl}
                          alt="QR Code contendo informações de pagamento do PIX"
                          lazy={false}
                          width={120}
                          height={120}
                          style={{ ...(!isPixExternal && { padding: 7 }) }}
                        />
                      </div>
                    }
                  />

                  <Conditional
                    condition={showPixSection}
                    renderIf={
                      <Pix
                        handleGeneratePixCode={handleGeneratePixCode}
                        showPixCode={!!pixCode && !!closedAgreement?.flowWithPix}
                        showUnavailablePixCode={
                          !pixCode && !!closedAgreement?.flowWithPix
                        }
                        pixCode={pixCode}
                        isLoadingTryAgain={isLoadingTryAgain}
                        handleCopyPixCode={handleCopyPixCode}
                        pixText={pixText}
                        isPixLoading={isPixLoading}
                        isPixSuccess={isPixSuccess}
                        isCodeCopied={isCodeCopied}
                        closedAgreement={closedAgreement}
                      />
                    }
                  />

                  <Conditional
                    condition={!!hasBillet}
                    renderIf={
                      <Billet
                        closedAgreement={closedAgreement}
                        billet={billet}
                      />
                    }
                  />

                  <BradescoLatePaymentMsg
                    withoutDiscountValue={bradescoDisclaimerValue}
                    withEmphasys
                    warning
                  />

                  <Conditional
                    condition={showPixSection || !!hasBillet}
                    renderIf={
                      <Message type="warning" customClass="info-warning">
                        <Text as="p" weight="regular">
                          A identificação do pagamento pode levar até{' '}
                          <strong>5 dias úteis</strong>.
                        </Text>
                      </Message>
                    }
                  />

                  <Conditional
                    condition={!!closedAgreement?.bankData}
                    renderIf={
                      <DebitAccount
                        debtData={debtData}
                        closedAgreement={closedAgreement}
                      />
                    }
                  />
                </>
              }
              renderElse={
                <>
                  <Conditional
                    condition={message[paymentStatus as keyof object]}
                    renderIf={
                      <Message
                        dangerouslySetInnerHTML={
                          message[paymentStatus as keyof object]
                        }
                        customClass="info-warning w-margin"
                      />
                    }
                  />

                  <div className="payment-section__buttons">
                    <Button
                      expand="x"
                      type="primary"
                      onClick={() => navigate(pageNames.myDebts.path)}
                    >
                      Negociar outra dívida
                    </Button>
                    <Button
                      expand="x"
                      type="secondary"
                      onClick={() => navigate(pageNames.myAgreements.path)}
                    >
                      Acompanhar meus acordos
                    </Button>
                  </div>
                </>
              }
            />
          </>
        }
      />
    </div>
  );
};

export default PaymentSection;
