import { IconMoreVertical } from '../icons/default';

type DebitCardDetailsProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: any;
};

const DebitCardDetails = ({ onClick, icon }: DebitCardDetailsProps) => {
  return (
    <a
      href="#"
      aria-label="button"
      onClick={onClick}
      className="debit-card__more"
    >
      {icon ? icon : <IconMoreVertical />}
    </a>
  );
};

export default DebitCardDetails;
