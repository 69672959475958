import Text from '@libs/atlas/components/Text';
import useClosedAgreementTutorial from './hooks';
import { Spinetic, SpineticItem } from 'react-spinetic';
import { carouselConfig } from './config';
import './styles.scss';
import LazyImage from '@libs/atlas/components/LazyImage';

interface StepByStepCarouselProps {
  firstInstallmentDate: string;
}

const ClosedAgreementTutorial = ({
  firstInstallmentDate,
}: StepByStepCarouselProps) => {
  const { steps } = useClosedAgreementTutorial({ firstInstallmentDate });

  return (
    <div className="closed-agreement-tutorial">
      <Text
        as="h2"
        variant="heading-four"
        color="white"
        className="closed-agreement-tutorial__title"
      >
        Passo a passo para quitar suas dívidas
      </Text>

      <Spinetic config={carouselConfig}>
        {steps.map(({ step, text, image }) => {
          return (
            <SpineticItem key={`closed-agreement-tutorial-step-${step}`}>
              <div className="closed-agreement-tutorial__step-container">
                <div className="closed-agreement-tutorial__step">
                  <span className="closed-agreement-tutorial__step-number">
                    {step}
                  </span>
                  <Text
                    as="span"
                    dangerouslySetInnerHTML={text}
                    color="white"
                    weight="regular"
                    className="closed-agreement-tutorial__step-text"
                  />
                </div>
                <LazyImage src={image} height={216} width={276} alt="" />
              </div>
            </SpineticItem>
          );
        })}
      </Spinetic>
    </div>
  );
};

export default ClosedAgreementTutorial;
