import { IconX } from '@libs/atlas/components/icons/default';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { useEffect, useState } from 'react';
import './styles.scss';
interface ModalProps {
  children?: React.ReactNode;
  show: boolean;
  onCloseClick?: () => void;
  showCloseButton?: boolean;
}

const Modal = ({
  children,
  show,
  onCloseClick,
  showCloseButton = true,
}: ModalProps) => {
  const [displayState, setDisplay] = useState(false);

  const closeModal = () => {
    if (!displayState) return;
    setDisplay(false);
  };
  const openModal = () => {
    setDisplay(true);
  };

  useEffect(() => {
    show ? openModal() : closeModal();

    document.body.classList.toggle('no-scroll', show);
  }, [show]);
  return (
    <Conditional
      condition={displayState}
      renderIf={
        <div className={`modal-container active`}>
          <div className="modal">
            <Conditional
              condition={showCloseButton}
              renderIf={
                <span className="modal-close" onClick={onCloseClick}>
                  <IconX />
                </span>
              }
            />
            <div className="modal-body">{children}</div>
          </div>
        </div>
      }
    />
  );
};
export default Modal;
