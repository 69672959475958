import { z } from 'zod';

export const isValidBirthdate = z
  .string()
  .refine((value) => validateBirthDate(value), {
    message:
      'Ops, data de nascimento incorreta. Por favor, confira a data informada e tente novamente.',
  });

function isDayValid(value: number) {
  const isANumber = typeof value === 'number';
  const isEmptyValue = !value && !isANumber;

  if (isEmptyValue) {
    return true;
  }

  const isFloatNumber = isANumber && !Number.isInteger(value);
  const numberFormatted = typeof value === 'string' ? parseInt(value) : value;
  const minDay = 1;
  const maxDay = 31;
  const isBiggerThanMinDay = numberFormatted >= minDay;
  const isSmallerThanMaxDay = numberFormatted <= maxDay;
  const isValidDay =
    !isFloatNumber && isBiggerThanMinDay && isSmallerThanMaxDay;

  return isValidDay;
}

function validateBirthDate(value: string): boolean {
  if (!value) return true;

  const params = value.split('/');
  const partialDate = false;

  const year = partialDate ? parseInt(params[1], 10) : parseInt(params[2], 10);
  const month = partialDate ? parseInt(params[0], 10) : parseInt(params[1], 10);
  const day = partialDate ? 1 : parseInt(params[0], 10);
  const currentDate = new Date();
  const currentYear = currentDate.getUTCFullYear();
  const currentDay = currentDate.getUTCDate();
  const currentMonth = currentDate.getUTCMonth() + 1;

  if (!isDayValid(day)) {
    return false;
  }

  if (month > 12) {
    return false;
  }

  if (
    year > currentYear ||
    year < 1900 ||
    (year === currentYear && month > currentMonth) ||
    (year === currentYear && month === currentMonth && day > currentDay)
  ) {
    return false;
  }

  const reference = new Date(Date.UTC(year, month - 1, day));
  const refYear = reference.getUTCFullYear();
  const refDay = reference.getUTCDate();
  const refMonth = reference.getUTCMonth() + 1;

  if (refYear !== year || refMonth !== month || refDay !== day) {
    return false;
  }

  return true;
}
