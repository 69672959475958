import Text from '@libs/atlas/components/Text';
import Accordion from '@libs/atlas/components/Accordion';
import { faq } from './data';

import './styles.scss';

const FaqSection = () => {
  return (
    <section className="faq-section">
      <Text
        as="h4"
        variant="heading-four"
        weight="semibold"
        className="faq-section__title"
      >
        Perguntas frequentes
      </Text>

      <Accordion items={faq} />
    </section>
  );
};

export default FaqSection;
