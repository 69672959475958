export const titleContent = {
  error: `<span class="title-section__title-highlight">Ops,</span> tivemos um problema!`,
  pending: `Parabéns, você fez um <span class="title-section__title-highlight">Acordo Certo</span>`,
  pixPaid: `Uhuuu, <span class="title-section__title-highlight">menos uma dívida!</span>`,
};

export const subtitleContent = {
  error: `Você pode conferir os detalhes do seu acordo e fazer o pagamento.`,
  pending: `Você pode conferir os detalhes do seu acordo e fazer o pagamento :)`,
  pixPaid: `Você pode conferir os detalhes do seu acordo abaixo :)`,
};
