import { useEffect, useState } from 'react';
import {
  addCookie,
  getCookie,
  removeCookie,
} from '@libs/utils/helpers/getSafeCookies';
import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import constants from '@common/constants';

const useGroupingPRAItauExperiment = (debt?: SimulatedDebt) => {
  const testCookieName = 'dx-grouping-itau';

  const VARIANTS = {
    A: 'without-grouping-itau',
    B: 'with-grouping-itau',
    C: 'with-pra-itau',
  };

  const currentResult = getCookie(testCookieName, false);
  const resultIsWithGrouping = !!currentResult?.includes(VARIANTS.B);
  const resultIsWithPRA = !!currentResult?.includes(VARIANTS.C);

  const groupingErrorModalCookie = 'groupingErrorModal';
  const alreadyShownModal = getCookie(groupingErrorModalCookie, false);
  const [openGroupingErrorModal, setOpenGroupingErrorModal] =
    useState<boolean>(false);

  const [hasGroupingAvailable, setHasGroupingAvailable] = useState<
    boolean | null
  >(null);

  const handleCloseGroupingErrorModal = () => {
    setOpenGroupingErrorModal(false);
  };

  const checkGroupingDebts = (debt: SimulatedDebt) => {
    const isItau = debt?.partner?.identifier.includes('itau');
    if (!isItau) return false;

    const hasGroupingContracts = debt?.propriedades?.find(
      (prop) =>
        prop?.chave === constants.debts.PROPERTIES.AVAILABLE_GROUP_CONTRACTS &&
        prop?.valorString
    );

    return hasGroupingContracts ? true : false;
  };

  const checkPRAItau = (debt?: SimulatedDebt) => {
    const isItau = debt?.partner?.identifier.includes('itau');
    if (!isItau) return { hasPRAItau: false };

    const { ITAU_NEGOTIATION_TYPE, ITAU_NEGOTIATION_TYPE_VALUE } =
      constants.debts.PROPERTIES;

    const PRAItau = debt?.opcaoPagamento?.some(({ propriedades }) => {
      const optType = propriedades?.find(
        ({ chave }) => chave === ITAU_NEGOTIATION_TYPE
      );

      return optType && optType?.valorString === ITAU_NEGOTIATION_TYPE_VALUE;
    });

    const PRAItauOptions = debt?.opcaoPagamento?.filter(({ propriedades }) => {
      const optType = propriedades?.find(
        ({ chave }) =>
          chave === constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE
      );
      return optType && optType?.valorString === ITAU_NEGOTIATION_TYPE_VALUE;
    });

    const PRAItauUpcomingOptions = debt?.opcaoPagamento?.filter(({ propriedades }) => {
      const optType = propriedades?.find(
          ({ chave, valorString }) =>
              chave === constants.debts.PROPERTIES.ITAU_NEGOTIATION_TYPE &&
              valorString === 'OVERDUE'
      );
      return !optType;
  });

    return {
      hasPRAItau: PRAItau,
      optionsOverdueOnlyItau: PRAItauOptions,
      optionsOverdueUpcomingItau: PRAItauUpcomingOptions,
    };
  };

  useEffect(() => {
    if (debt?.groupedDebts?.errorWhileGrouping && !alreadyShownModal) {
      setOpenGroupingErrorModal(true);
      addCookie({
        key: groupingErrorModalCookie,
        value: 'true',
      });
    }
  }, []);

  const handleCookieGroupingErrorModal = () => {
    if (alreadyShownModal) removeCookie(groupingErrorModalCookie);
  };

  const hasGroupedDebt = debt?.groupedDebts?.contractsAmount ?? [];

  return {
    resultIsWithPRA,
    resultIsWithGrouping,
    checkGroupingDebts,
    checkPRAItau,
    openGroupingErrorModal,
    handleCloseGroupingErrorModal,
    hasGrouping: resultIsWithGrouping && hasGroupingAvailable,
    isGroupedDebt: resultIsWithGrouping && hasGroupedDebt?.length > 1,
    setHasGroupingAvailable,
    handleCookieGroupingErrorModal,
  };
};

export default useGroupingPRAItauExperiment;
