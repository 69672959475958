import {
  COLOR_WARNING_50,
  IconAlertTriangle,
} from '@consumidor-positivo/aurora';
import Message from '@libs/atlas/components/Message';
import Text, { TextProps } from '@libs/atlas/components/Text';
import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import './styles.scss';

type BradescoLatePaymentMsgProps = {
  withEmphasys?: boolean;
  withoutDiscountValue?: number;
  warning?: boolean;
} & TextProps;

const BradescoLatePaymentMsg = ({
  withEmphasys,
  withoutDiscountValue,
  warning,
  ...textProps
}: BradescoLatePaymentMsgProps) => {
  const empashizedText = 'após a data de vencimento';

  if (!withoutDiscountValue) return null;

  const InnerText = () => (
    <Text as="p" weight="regular" {...textProps}>
      A partir da segunda parcela, pagando{' '}
      {withEmphasys ? <strong>{empashizedText}</strong> : empashizedText}, você
      perderá o desconto e o valor da parcela será{' '}
      {currencyToLocale(withoutDiscountValue)}.
    </Text>
  );

  return (
    <div className="bradesco-installment-disclaimer">
      {warning ? (
        <Message
          type="custom"
          icon={<IconAlertTriangle rawColor={COLOR_WARNING_50} />}
          customClass="bradesco-disclaimer"
        >
          <InnerText />
        </Message>
      ) : (
        <InnerText />
      )}
    </div>
  );
};

export default BradescoLatePaymentMsg;
