import CardOfferAppend from './Append';
import CardOfferDetails from './Details';
import CardOfferRoot from './Root';
import CardOfferHeader from './Header/Header';
import HeaderMainTitle from './Header/HeaderMainTitle';
import CardOfferText from './Text';
import CardOfferButton from './Button';
import CardOfferTag from './Tag';
import CardOfferPartner from './Partner';
import CardOfferDisclaimer from './Disclaimer';
import './styles.scss';

export default {
  Append: CardOfferAppend,
  Details: CardOfferDetails,
  Root: CardOfferRoot,
  Header: CardOfferHeader,
  HeaderMainTitle: HeaderMainTitle,
  Text: CardOfferText,
  Button: CardOfferButton,
  Tag: CardOfferTag,
  Partner: CardOfferPartner,
  Disclaimer: CardOfferDisclaimer,
};
