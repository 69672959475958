import React from 'react';
import Text from '@libs/atlas/components/Text';

const CardOfferDisclaimer = ({ hasMargin = false }) => {
  return (
    <Text as="p" className={`card-offer-container__disclaimer ${hasMargin ? 'card-offer-container__disclaimer--has-margin' : ''}`}>
      *Sujeito à análise de crédito
    </Text>
  );
};

export default CardOfferDisclaimer;
