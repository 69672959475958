import React from 'react';
import { ForwardRefRenderFunction, forwardRef, useEffect } from 'react';

import Conditional from '@libs/atlas/components/misc/Conditional';
import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';

import { currencyToLocale } from '@libs/utils/helpers/currencyToLocale';

import constants from '@common/constants';
import useDetails from './hooks';
import * as Types from './types';
import '../styles.scss';

const DetailsModal: ForwardRefRenderFunction<Types.DetailsModalRef> = (
  _,
  ref
) => {
  const {
    isOpen,
    isLoading,

    currentDebt,
    formattedCetProps,
    debtDetails,
    infosCielo,

    buttonText,

    handleClick,
    handleClose,

    titleMap,
    identifyKeysDetails,
    infosModal,
  } = useDetails(ref);

  return (
    <Drawer
      title={infosModal.title}
      subTitle={infosModal.subTitle}
      isActive={isOpen}
      onClose={handleClose}
      buttonText={buttonText}
      onClickButton={handleClick}
      isLoading={isLoading}
      className="details-modal"
    >
      <Conditional
        condition={formattedCetProps}
        renderIf={
          <ul className="debt-modal__list__item">
            <Text as="h3" className="debt-modal__subtitle">
              Dados gerais
            </Text>

            {currentDebt?.bankAccounts?.map((bankAccount: any, i: number) => {
              return (
                <li
                  className="debt-modal__list__line"
                  key={Math.random() * bankAccount.id}
                >
                  <Text as="h4" className="debt-modal__list__title">
                    Agência e conta - {i + 1}
                  </Text>

                  <Text as="p" className="debt-modal__list__description">
                    {bankAccount?.agency} / {bankAccount?.account}
                  </Text>
                </li>
              );
            })}

            {formattedCetProps &&
             constants.debts.PROPERTIES.CET_KEYS?.map((key, i) => (
                <Conditional
                  key={`${key}-${i}`}
                  condition={formattedCetProps[key]?.title}
                  renderIf={
                    <li className="debt-modal__list__line">
                      <Text as="h4" className="debt-modal__list__title">
                        {formattedCetProps[key]?.title}
                      </Text>
                      <Text
                        as="p"
                        className="debt-modal__list__description"
                        weight="regular"
                      >
                        {formattedCetProps[key]?.valueString ||
                          formattedCetProps[key]?.valueDecimal}
                      </Text>

                      <Conditional
                        condition={formattedCetProps?.[key]?.descriptionIOF}
                        renderIf={
                          <Text as="span" className="debt-modal__list__sub-li">
                            {formattedCetProps?.[key]?.descriptionIOF}
                          </Text>
                        }
                      />
                    </li>
                  }
                />
              ))}
          </ul>
        }
      />

      <Conditional
        condition={isOpen}
        renderIf={debtDetails?.map(
          (item: { [key: string]: number }, i: number) => (
            <React.Fragment
              key={`details-${item.id}-${Math.random() * i}-contract-included`}
            >
              <ul className="debt-modal__list__item">
                {i === 0 && (
                  <Text as="h3" className="debt-modal__subtitle">
                    Contratos inclusos
                  </Text>
                )}

                {Object.entries(item)
                  .filter(([key]) => key in titleMap)
                  .map(([key, value]) => {
                    return (
                      !!value && (
                        <li className="debt-modal__list__line" key={key}>
                          <Text as="h4" className="debt-modal__list__title">
                            {titleMap[key as keyof Types.TitleMapProps]}:
                          </Text>

                          <Text
                            as="p"
                            className="debt-modal__list__description"
                          >
                            {identifyKeysDetails(key, value)}
                          </Text>
                        </li>
                      )
                    );
                  })}
              </ul>
            </React.Fragment>
          )
        )}
      />

      <Conditional
        condition={!!infosCielo?.hasPropertiesCielo}
        renderIf={
          <ul className="debt-modal__list__item">
            {infosCielo?.propertiesCielo?.map((prop: any, i: number) => {
              return (
                <React.Fragment
                  key={`details-${prop.id}-${Math.random() * i}-infos-cielo`}
                >
                  <Conditional
                    condition={!!infosCielo.title && i === 0}
                    renderIf={
                      <Text as="h3" className="debt-modal__subtitle">
                        {infosCielo.title}
                      </Text>
                    }
                  />

                  <Conditional
                    condition={!!infosCielo?.hasPropertiesCielo}
                    renderIf={
                      <li>
                        <Text as="h4" className="debt-modal__list__title">
                          {prop.stringValue || prop.valorString}:
                        </Text>
                        <Text as="p" className="debt-modal__list__description">
                          {currencyToLocale(
                            prop.decimalValue || prop.valorDecimal
                          )}
                        </Text>
                      </li>
                    }
                  />
                </React.Fragment>
              );
            })}
          </ul>
        }
      />
    </Drawer>
  );
};

export default forwardRef(DetailsModal);
