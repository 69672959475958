import { useEffect, useRef } from 'react';

interface UseInViewObserverOptions {
  triggerOnce?: boolean;
}

export default function useInViewObserver(
  callback: () => void,
  options: UseInViewObserverOptions = { triggerOnce: true }
) {
  const { triggerOnce } = options;
  const hasTriggered = useRef(false);
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!hasTriggered.current || !triggerOnce) {
            callback();
            hasTriggered.current = true;
          }
        } else if (!triggerOnce) {
          hasTriggered.current = false;
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [callback, triggerOnce]);

  return observerRef;
}
