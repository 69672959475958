import { ReactNode } from "react";

type DebitCardPriceLineProps = {
  children: ReactNode,
  visible?: boolean
};

const DebitCardPriceLine = ({ visible = true, children }: DebitCardPriceLineProps) => {
  return (
    <div className={`debit-card__line ${!visible && "not-visible"}`}>
      {children}
    </div>
  );
};

export default DebitCardPriceLine;
