import { ForwardRefRenderFunction, forwardRef } from 'react';

import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';

import useNegative, { NegativeModalRef } from './hooks/useNegative';
import "./styles.scss";

const NegativeModal: ForwardRefRenderFunction<NegativeModalRef> = (_, ref) => {
  const {
    currentDebt,
    isOpen,
    isLoading,
    handleClick,
    handleClose,
    buttonText
  } = useNegative(ref);

  return (
    <Drawer
      className='negative-modal'
      title={`Esta é uma dívida negativada com a ${currentDebt?.partner?.name}`}
      isActive={isOpen}
      onClose={handleClose}
      buttonText={buttonText}
      onClickButton={handleClick}
      isLoading={isLoading}
    >
      <Text
        as="p"
        variant="body-large"
        weight="regular"
        className="debt-modal__text"
      >
        Você possui uma dívida negativada com a empresa {currentDebt?.partner?.name} que está
        afetando negativamente seu histórico de crédito. Isso pode dificultar
        a obtenção de empréstimos, financiamentos ou até mesmo aluguel de
        imóveis no futuro. Renegociá-la é fundamental para limpar seu nome e
        recuperar seu crédito
      </Text>
    </Drawer>
  );
};

export default forwardRef(NegativeModal);
