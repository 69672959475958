import LazyImage from '@libs/atlas/components/LazyImage';
import logoPartner from '../../assets/partners/AC+IQ.svg';
import { PartnerProps } from './types';

const CardOfferPartner = ({ alt }: PartnerProps) => {
  return (
    <div className="card-offer-container__partner">
      <LazyImage src={logoPartner} alt={alt} />
    </div>
  );
};
export default CardOfferPartner;
