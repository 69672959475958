import classNames from 'classnames';
import { ReactNode } from 'react';

type DebitCardRootProps = {
  children: ReactNode;
  large?: boolean;
  itemRef?: any
};

const DebitCardRoot = ({ children, large, itemRef }: DebitCardRootProps) => {
  return (
    <div
      className={classNames('debit-card', { 'debit-card--large': large })}
      ref={itemRef}
    >
      {children}
    </div>
  );
};

export default DebitCardRoot;
