import { useImperativeHandle, useState } from 'react';

import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import { useRedline } from '@libs/redline';

interface OpenProps {
  debt: SimulatedDebt;
}

export interface LeadRedirectModalRef {
  open: (args: OpenProps) => void;
  loading: (loading: boolean) => void;
}

const useLeadRedirect = (
  ref: React.ForwardedRef<LeadRedirectModalRef>,
  originId?: string
) => {
  const [currentDebt, setCurrentDebt] = useState<SimulatedDebt | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useRedline();

  const details = currentDebt?.detalhes;
  const detail = details ? details[0] : null;
  const detailProvider =
    currentDebt?.partner.redirectProvider || currentDebt?.partner.name || '';
  const redirectProvider = currentDebt?.partner.redirectProvider;
  const buttonText = `Negociar com ${redirectProvider || detailProvider}`;

  const handleClose = () => {
    setCurrentDebt(null);
    setIsOpen(false);
  };

  const handleClick = async () => {
    const properties = currentDebt?.propriedades || [];
    const walletLinkProp = properties.find(
      ({ chave }) => (chave || '').toLowerCase() === 'wallet_link'
    );
    const walletLink = walletLinkProp?.valorString || 'https://acordocerto.com.br';

    await track.experience.redirectRequested({
      originId: originId || '',
      redirectRequestedId: currentDebt?.partner?.identifier,
      redirectRequestedUrl: walletLink,
    });
    window?.open(walletLink, '_blank');
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open({ debt }) {
      setCurrentDebt(debt);
      setIsOpen(true);
    },
    loading(loading) {
      setIsLoading(loading);
    },
  }));

  return {
    buttonText,
    detail,
    detailProvider,
    isOpen,
    isLoading,
    redirectProvider,
    handleClose,
    handleClick,
  };
};

export default useLeadRedirect;
