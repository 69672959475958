import { useImperativeHandle, useState } from 'react';

import constants from '@common/constants';
import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import useSimulatedDebt from '@common/hooks/useSimulatedDebt';

import { legacyBase64Encrypt } from '@libs/utils/helpers/base64';

import { currencyToLocale } from "@libs/utils/helpers/currencyToLocale";

interface OpenProps {
  debt: SimulatedDebt;
  goToInstallments: (paymentMethod: string) => void;
}

export interface ReenrolmentModalRef {
  open: (args: OpenProps) => void;
}

const useReenrolment = (ref: React.ForwardedRef<ReenrolmentModalRef>) => {
  const { checkReenrolment } = useSimulatedDebt();

  const [currentDebt, setCurrentDebt] = useState<SimulatedDebt | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasWithReenrolment, setHasWithReenrolment] = useState(false);
  const [hasWithoutReenrolment, setHasWithoutReenrolment] = useState(false);
  const [valueWithReenrolment, setValueWithReenrolment] = useState('');
  const [valueWithoutReenrolment, setValueWithoutReenrolment] = useState('');
  const [goToInstallments, setGoToInstallments] = useState<
    ((paymentMethod: string) => void) | null
  >(null);

  const filterInstallments = (selectedOption: string) => {
    const paymentOptions = currentDebt?.opcaoPagamento.filter(
      ({ propriedades }) => {
        const optType = propriedades?.find(
          (prop) =>
            prop.chave === 'IS_REMATRICULA_INCLUDED'
        );

        const withReenrolment = selectedOption === 'with_reenrolment' && optType?.valorBoolean === true;
        const withoutReenrolment = selectedOption === 'without_reenrolment' && optType?.valorBoolean === false;

        return withReenrolment || withoutReenrolment
      }
    );

    return { ...currentDebt, opcaoPagamento: paymentOptions };
  };

  const addDebtToLocalStorage = (selectedOption: string) => {
    const isWithReenrolment = selectedOption === 'with_reenrolment';
    const filteredDebt = filterInstallments(selectedOption);

    const encryptedDebt = legacyBase64Encrypt(
      JSON.stringify({
        ...filteredDebt,
        expTime: new Date(),
        ...(isWithReenrolment && {
          typeSelected: selectedOption,
        }),
      })
    );

    const encryptedPaymentOption = legacyBase64Encrypt(
      JSON.stringify(filteredDebt?.opcaoPagamento?.[0])
    );

    localStorage.setItem(constants.debts.DEBT, encryptedDebt);

    localStorage.setItem(constants.debts.PAYMENT_OPTION, encryptedPaymentOption);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentDebt(null);
    setGoToInstallments(null);
  };

  const handleClickOption = (option: string) => {
    handleClose();

    setTimeout(() => {
      addDebtToLocalStorage(option);
      if (currentDebt && goToInstallments) {
        const [paymentOption] = currentDebt.opcaoPagamento;
        goToInstallments(paymentOption.paymentMethodName);
      }
    }, 100);
  };


  useImperativeHandle(ref, () => ({
    open({ debt, goToInstallments }) {
      setCurrentDebt(debt);
      setIsOpen(true);
      setGoToInstallments(() => goToInstallments);

      const {
        hasWithReenrolment: withReenrolment,
        hasWithoutReenrolment: withoutReenrolment,
        optionsWithReenrolment, 
        optionsWithoutReenrolment,
      } = checkReenrolment(debt);
      const currentValueWithReenrolment = currencyToLocale(optionsWithReenrolment[0]?.valorAcordo);
      const currentValueWithoutReenrolment = currencyToLocale(optionsWithoutReenrolment[0]?.valorAcordo);

      setValueWithReenrolment(currentValueWithReenrolment)
      setValueWithoutReenrolment(currentValueWithoutReenrolment)
      setHasWithReenrolment(withReenrolment);
      setHasWithoutReenrolment(withoutReenrolment);
    },
  }));

  return {
    isOpen,
    hasWithReenrolment,
    hasWithoutReenrolment,
    valueWithReenrolment,
    valueWithoutReenrolment,
    handleClickOption,
    handleClose,
  };
};

export default useReenrolment;
