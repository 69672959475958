import Text from '@libs/atlas/components/Text';
import { Conditional } from '@consumidor-positivo/aurora';
import { HeaderMainTitleProps } from '../types';

import { getLimitMessage } from '@hub/helpers/handleLimitMessage';
import { useEffect } from 'react';

const HeaderMainTitle = ({
  title,
  subtitle,
  limit,
  slug = '',
}: HeaderMainTitleProps) => {
  const cardHasLimit = Boolean(limit);
  const limitMessage = getLimitMessage(slug, limit);

  return (
    <Conditional
      condition={!cardHasLimit}
      renderIf={
        <div className="card-offer-container__header--title">
          <Text as="h3" color="common" variant='heading-four' weight="bold">
            {title}
          </Text>
          <Text weight="regular" color="light" as="p" variant="body-large">
            {subtitle}
          </Text>
        </div>
      }
      renderElse={
        <div className="card-offer-container__header--title-with-limit">
          <Text weight="semibold" as="p" variant="body-large" color="light">
            {title}
          </Text>
          <Text as="h3" color="common" variant='heading-four' weight="semibold">
            {limitMessage}
          </Text>
        </div>
      }
    />
  );
};

export default HeaderMainTitle;
