import { pageNames } from '@common/routes/pageNames';
import Button from '@libs/atlas/components/Button';
import DashedDivider from '@libs/atlas/components/DashedDivider';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import ConfirmAgreementAccordion from '../../sharedElements/ConfirmAgreementAccordion';
import ConfirmAgreementDetails from '../../sharedElements/ConfirmAgreementDetails';
import ConfirmAgreementHeader from '../../sharedElements/ConfirmAgreementHeader';
import ConfirmAgreementValues from '../../sharedElements/ConfirmAgreementValues';
import Information from '../../sharedElements/Information';
import WarningNegotiation from '../../sharedElements/WarningNegotiation';
import ClosedAgreementTutorial from './components/ClosedAgreementTutorial';
import FaqSection from './components/FaqSection';
import PaymentSection from './components/PaymentSection';
import TitleSection from './components/TitleSection';
import PollingLoader from '@common/components/PollingLoader';
import { useNavigate } from 'react-router-dom';
import useClosedAgreement from '../hooks';

import './styles.scss';

const ClosedAgreementV1 = () => {
  const navigate = useNavigate();

  const {
    closedAgreement,
    debtData,
    pixCode,
    qrCodeUrl,
    paymentStatus,
    isPixExternal,
    pixText,
    selectedBankAccount,
    billet,
    hasBillet,
    loading,
    firstInstallmentDate,
    handleCopyPixCode,
    isLoadingTryAgain,
    isPixLoading,
    isPixSuccess,
    importantWarningAfterAgreement,
    isRenegotiation,
    isCodeCopied,
    statusLabels,
    handleGeneratePixCode,
    showPollingBilletComp,
    showSkeleton,
  } = useClosedAgreement();

  const { agreement, debt, paymentOption } = closedAgreement || {};

  return (
    <div className="closed-agreement-page">
      <div className="closed-agreement-page__title">
        <TitleSection paymentStatus={paymentStatus} />
      </div>

      <div className="closed-agreement-page__container">
        <Conditional
          condition={!!closedAgreement?.debt?.typeSelected}
          renderIf={
            <WarningNegotiation
              typeSelected={closedAgreement?.debt?.typeSelected}
            />
          }
        />

        <Conditional
          condition={isRenegotiation}
          renderIf={
            <Information importantInfoText={importantWarningAfterAgreement} />
          }
        />

        <div className="closed-agreement-page__resume">
          <ConfirmAgreementHeader debt={debt} debtData={debtData} />

          <DashedDivider />
          <ConfirmAgreementValues
            debtData={debtData}
            path="acordo-fechado"
            firstInstallmentDate={firstInstallmentDate}
          />
          <DashedDivider />

          <Conditional
            condition={showPollingBilletComp}
            renderIf={<PollingLoader />}
            renderElse={
              <PaymentSection
                showSkeleton={showSkeleton}
                debtData={debtData}
                isLoadingBillet={loading}
                statusLabels={statusLabels}
                closedAgreement={closedAgreement}
                isPixExternal={isPixExternal}
                qrCodeUrl={qrCodeUrl}
                pixCode={pixCode}
                handleCopyPixCode={handleCopyPixCode}
                isLoadingTryAgain={isLoadingTryAgain}
                isPixLoading={isPixLoading}
                isPixSuccess={isPixSuccess}
                pixText={pixText}
                paymentStatus={paymentStatus}
                isCodeCopied={isCodeCopied}
                handleGeneratePixCode={handleGeneratePixCode}
                billet={billet}
                hasBillet={hasBillet}
              />
            }
          />
          <ConfirmAgreementDetails
            debtData={debtData}
            debt={debt}
            paymentOption={paymentOption}
            selectedBankAccount={selectedBankAccount}
          />

          <ConfirmAgreementAccordion
            debtData={debtData}
            debt={debt}
            paymentOption={paymentOption}
            path="acordo-fechado"
          />
        </div>
      </div>

      <div className="closed-agreement-page__tutorial-container">
        <ClosedAgreementTutorial firstInstallmentDate={firstInstallmentDate} />
      </div>
      <div className="closed-agreement-page__container">
        <Text as="p" weight="regular" className="closed-agreement-page__iof">
          *O IOF (Imposto sobre Operações Financeiras) foi calculado conforme
          determinações do Banco Central do Brasil. A taxa foi financiada e já
          está inclusa no valor das parcelas do seu acordo.
        </Text>
        <div className="closed-agreement-page__buttons">
          <Button
            type="secondary"
            onClick={() => navigate(pageNames.myDebts.path)}
          >
            Negociar outra dívida
          </Button>
          <Button
            type="secondary"
            onClick={() => navigate(pageNames.myAgreements.path)}
          >
            Ir para Meus Acordos
          </Button>
        </div>
        <FaqSection />
      </div>
    </div>
  );
};

export default ClosedAgreementV1;
