import { ReactNode } from 'react';

type DebitCardAppendProps = {
  children: ReactNode;
};

const DebitCardAppend = ({ children }: DebitCardAppendProps) => {
  return <div className="debit-card__hold">{children}</div>;
};

export default DebitCardAppend;
