import { ReactNode } from 'react';

import classNames from 'classnames';

import Conditional from '@libs/atlas/components/misc/Conditional';
import Skeleton from '@libs/atlas/components/DebitCard/Skeleton';
import Details from '@libs/atlas/components/DebitCard/Details';

type DebitCardHeaderProps = {
  children?: ReactNode;
  logo?: string;
  name?: string;
  showDetailsButton?: boolean;
  onDetailsClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  isPartial?: boolean;
};

const DebitCardHeader = ({
  children,
  logo = '',
  name = '',
  showDetailsButton,
  onDetailsClick,
  isLoading = false,
  isPartial = false,
}: DebitCardHeaderProps) => {
  const handleDetailsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (onDetailsClick) {
      onDetailsClick(e);
    }
  };

  return (
    <div className="debit-card__hold debit-card__hold--head">
      <div
        className={classNames('debit-card__logo', {
          'debit-card__logo--loading': isLoading,
        })}
      >
        <Conditional
          condition={isLoading}
          renderIf={<Skeleton type="logo" />}
          renderElse={
            <img src={logo} alt={`Logo ${name}`} width={40} height={40} />
          }
        />
      </div>
      <div className="debit-card__infos">
        <Conditional
          condition={isLoading}
          renderIf={<Skeleton size="sm" />}
          renderElse={<div className="debit-card__name">{name}</div>}
        />

        <Conditional
          condition={isLoading || isPartial}
          renderIf={
            <div className="debit-card__infos--loading">
              <Skeleton size="lg" />
              <Skeleton size="md" />
            </div>
          }
          renderElse={children}
        />
      </div>
      <Conditional
        condition={!!(onDetailsClick && showDetailsButton)}
        renderIf={
          <div className="debit-card__details">
            <Details onClick={handleDetailsClick} />
          </div>
        }
      />
    </div>
  );
};

export default DebitCardHeader;
