import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetOffers from '@common/hooks/useOffers';
import useInViewObserver from '@common/hooks/useInViewObserver';
import { delay } from '@libs/utils/helpers/delay';
import { pageNames } from '@common/routes/pageNames';
import { useRedline } from '@libs/redline';
import { OfferCarouselCardProps } from '../types';
import useTrackOffer from '@common/hooks/useTrackOffer';

export default function useOfferCarouselCard({
  offer,
  index,
  location,
}: OfferCarouselCardProps) {
  const [thisBtnLoading, setThisBtnLoading] = useState(false);
  const { sendEventProductViewed, onClickOffer } = useGetOffers();
  const navigate = useNavigate();
  const { trackElementClicked } = useTrackOffer();

  const onClickBtn = async () => {
    setThisBtnLoading(true);
    await delay(50);

    await onClickOffer(offer, location, index);
    setThisBtnLoading(false);
  };

  const handleDetailRedirect = async () => {
    trackElementClicked({
      elementType: 'card/button',
      name: offer.content.slug,
      location: location,
      text: 'Ver detalhes'
    });

    const detailUrl = pageNames.details.path.replace(':slug', offer.content.slug);
    await delay(150);
    return navigate(detailUrl);
  }

  const observerRef = useInViewObserver(async () => {
    await delay(150);
    sendEventProductViewed(offer, index, location);
  });

  return { thisBtnLoading, onClickBtn, handleDetailRedirect, observerRef };
}
