import { ForwardRefRenderFunction, forwardRef } from 'react';

import Conditional from '@libs/atlas/components/misc/Conditional';
import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';

import useLeadRedirect, { LeadRedirectModalRef } from './hooks/useLeadRedirect';
import "./styles.scss";

const LeadRedirectModal: ForwardRefRenderFunction<LeadRedirectModalRef> = (_, ref) => {
  const originId = "partner-redirect-modal";
  const {
    buttonText,
    detail,
    detailProvider,
    isOpen,
    isLoading,
    redirectProvider,
    handleClose,
    handleClick
  } = useLeadRedirect(ref, originId);

  return (
    <Drawer
      title="Detalhes da proposta"
      isActive={isOpen}
      onClose={() => handleClose()}
      buttonText={buttonText}
      onClickButton={handleClick}
      isLoading={isLoading}
    >
      <div className="debt-modal__list">
        <Conditional
          condition={!!detail && (!!detail?.produto || !!detail?.contrato)}
          renderIf={
            <div className="debt-modal__list__item">
              <Conditional
                condition={!!detail?.produto}
                renderIf={
                  <div className="debt-modal__list__line">
                    <Text as="h4" className="debt-modal__list__title">
                      Produto:
                    </Text>
                    <Text as="h4" className="debt-modal__list__description">
                      {detail?.produto}
                    </Text>
                  </div>
                }
              />

              <Conditional
                condition={!!detail?.contrato}
                renderIf={
                  <div className="debt-modal__list__line">
                    <Text as="h4" className="debt-modal__list__title">
                      Contrato:
                    </Text>
                    <Text as="h4" className="debt-modal__list__description">
                      {detail?.contrato}
                    </Text>
                  </div>
                }
              />
            </div>
          }
        />
        <div className="debt-modal__proposal-container">
          <Text
            as="p"
            variant="body-large"
            weight="regular"
            className="debt-modal__text"
          >
            {`Este contrato está disponível para negociação apenas nos canais do produto
            ${detail?.produto || detailProvider}, com uma condição especial para você. Para mais
            informações sobre a dívida, ou em caso de não reconhecimento, entre em contato diretamente com
            ${redirectProvider || detailProvider}.`}
          </Text>
        </div>
      </div>
    </Drawer>
  );
};

export default forwardRef(LeadRedirectModal);
