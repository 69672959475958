import './styles.scss';

export function OfferCarouselCardPlaceholder() {
  return (
    <div className="card-placeholder featured">
      <div className="card-placeholder-container">
        <div className="card-placeholder-container__tag">
          &nbsp;
        </div>
        <div className="card-placeholder-container__header">
          <div className="card-placeholder-container__header--holder">
            <div className="card-placeholder-container__logo-img card-placeholder-container__logo-img--cards large" />
          </div>
          <div className="card-placeholder-container__header--title">
            <p className="card-placeholder-container__text card-placeholder-container__text--title ac-text ac-text--heading-four ac-text--color-common ac-text--weight-semibold">
              &nbsp;
            </p>
            <p className="card-placeholder-container__text">
              &nbsp;
            </p>
            <p className="card-placeholder-container__text card-placeholder-container__text--half">
              &nbsp;
            </p>
          </div>
        </div>
        <span className="card-placeholder-container__more">
          <p className="ac-text ac-text--body-large ac-text--color-principal ac-text--weight-semibold">
            &nbsp;
          </p>
        </span>
        <div className="card-placeholder-container__btn">
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  );
}
