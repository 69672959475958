import { ReactNode } from 'react';

import DashedDivider from '@libs/atlas/components/DashedDivider';

type DebitCardPriceProps = {
  children: ReactNode;
};

const DebitCardPrice = ({ children }: DebitCardPriceProps) => (
  <div className="debit-card__price">
    <div className="debit-card__hold">{children}</div>
    <DashedDivider/>
  </div>
);

export default DebitCardPrice;
