import Text from '@libs/atlas/components/Text';
import { IconChevronRight } from '@libs/atlas/components/icons/default';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { CardOfferTextProps } from './types';

const CardOfferText = ({
  description = "",
  hasRightArrow = false,
}: CardOfferTextProps) => {
  return (
    <div className="card-offer-container__text">
      <div className='card-offer-container__text-container'>
        <Conditional
          condition={!!description}
          renderIf={
            <Text as="p" weight="regular" variant='body-large' className='card-offer-container__text--description'>
              {description}
            </Text>
          }
        />
      </div>
      <Conditional
        condition={hasRightArrow}
        renderIf={
          <div className="card-offer-container__text--arrow">
            <IconChevronRight />
          </div>
        }
      />
    </div>
  );
};

export default CardOfferText;
