import { useEffect, useState } from 'react';
import { getProperties } from '@common/hooks/useDebts/helpers';
import constants from '@common/constants';

import {
  SimulatedDebt,
  SimulatedPaymentOption,
} from '@common/apis/simulated-debt/types';

import { DebtData } from '@common/hooks/useDebts/types';
import { CurrentExtraInfo } from '@dues/pages/Partner/helpers/types';

interface UseConfirmAgreemntAccordion {
  debt: SimulatedDebt;
  paymentOption: SimulatedPaymentOption;

  debtData: DebtData;
  path?: string;
  currentExtraInfo?: CurrentExtraInfo;
}

interface ItemAccordion {
  title: string;
  content: [] | string[] | string;
  isOpen?: boolean;
}

export const useConfirmAgreementAccordion = ({
  debt,
  paymentOption,

  debtData,
  path,
  currentExtraInfo,
}: UseConfirmAgreemntAccordion) => {
  const [accordionItems, setAccordionItems] = useState<ItemAccordion[]>([]);

  const { hasInstallments } = debtData?.paymentOpt;

  const {
    isDebitInAccountAndNotBradesco,
    hasDebitInAccountEntry,
    hasEntryForDebitInAcc,
    id: methodID,
    singlePaymentMethod,
    changeModalityAvailable,
    debitInAccountRequired,
  } = debtData?.paymentMeth || {};

  const setAboutTheBillet = () => {
    const aboutTheBillet = [
      {
        title: 'Sobre o boleto',
        content: [
          <p
            key={`aboutTheBillet-p1`}
            dangerouslySetInnerHTML={{
              __html:
                'Após concluir o acordo, o boleto será gerado e ficará disponível para você, além de ser enviado para o seu e-mail.',
            }}
          />,
        ],
        expanded: false,
      },
    ];

    const isBilletSelected = methodID === 'BILLET';
    const hasBillet =
      (isDebitInAccountAndNotBradesco && !singlePaymentMethod) ||
      (hasEntryForDebitInAcc && !singlePaymentMethod) ||
      (isBilletSelected && singlePaymentMethod);

    return hasBillet ? aboutTheBillet : [];
  };

  const setAboutTheAbatement = () => {
    const aboutTheAbatement = [
      {
        title: 'Sobre o abatimento',
        content: [
          <p
            key={`aboutTheAbatement-p1`}
            dangerouslySetInnerHTML={{
              __html:
                'Negociando com abatimento, seu nome sairá dos orgãos de proteção ao crédito, mas não poderá contratar novos empréstimos e manter limite de crédito no Banco do Brasil.',
            }}
          />,
          <p
            key={`aboutTheAbatement-p2`}
            dangerouslySetInnerHTML={{
              __html:
                'Caso queira ter essas opções no futuro, será necessário procurar uma agência BB para pagar abatimento concedido.',
            }}
          />,
        ],
        expanded: false,
      },
    ];

    return debt?.typeSelected === 'with_abatement' ? aboutTheAbatement : [];
  };

  const setAboutAccountDebiting = () => {
    const {
      isDebitInAccountAndNotBradesco,
      hasBankAccount,
      id,
      singlePaymentMethod,
    } = debtData?.paymentMeth || {};

    const isDebitAccSelected = id === 'DEBIT_ACCOUNT';
    const isConfirmationPg = path === 'confirmacao';

    const aboutAccountDebiting = [
      {
        title: 'Sobre o débito em conta',
        content: [
          <p
            key={`aboutAccountDebiting-p1`}
            dangerouslySetInnerHTML={{
              __html: `O pagamento do débito em conta funciona da seguinte forma: O pagamento da primeira parcela/entrada será por meio de ${singlePaymentMethod && id === 'PIX' ? 'pix' : 'boleto'
                }. As demais parcelas serão debitadas automaticamente de sua conta.`,
            }}
          />,
        ],
        expanded: false,
      },
    ];

    const hasAboutDebitAcc =
      (!hasBankAccount && isDebitAccSelected && isConfirmationPg) ||
      (isDebitInAccountAndNotBradesco && !isConfirmationPg);

    return hasAboutDebitAcc ? aboutAccountDebiting : [];
  };

  const setConditionsOfTheAgreement = () => {
    const directDebtCompInfoDebt = getProperties(
      debt?.propriedades || [],
      constants.debts.PROPERTIES.DEBIT_INFO
    );

    const compInfoDebt = getProperties(
      debt?.propriedades || [],
      constants.debts.PROPERTIES.COMPLEMETARY_INFO
    );

    const compInfoPaymentOpt = getProperties(
      paymentOption?.propriedades || [],
      constants.debts.PROPERTIES.COMPLEMETARY_INFO
    );

    const contentConditionsOfTheAgreement = [
      ...(currentExtraInfo?.warnings?.importantInfoText
        ? [
          <p
            key={`directDebtCompInfoDebt-${Math.random()}`}
            dangerouslySetInnerHTML={{
              __html: `${currentExtraInfo?.warnings?.importantInfoText}`,
            }}
          />,
        ]
        : []),
      ...directDebtCompInfoDebt.map((obj: any, i: number) => (
        <p
          key={`directDebtCompInfoDebt-${Math.random() * i}`}
          dangerouslySetInnerHTML={{ __html: `${obj.valorString}` }}
        />
      )),
      ...compInfoDebt.map((obj: any, i: number) => (
        <p
          key={`compInfoDebt-${Math.random() * i}`}
          dangerouslySetInnerHTML={{ __html: `<br/> ${obj.valorString}` }}
        />
      )),
      ...compInfoPaymentOpt.map((obj: any, i: number) => (
        <p
          key={`compInfoPaymentOpt-${Math.random() * i}`}
          dangerouslySetInnerHTML={{ __html: `<br/> ${obj.valorString}` }}
        />
      )),
    ];

    const conditionsOfTheAgreement = [
      {
        title: 'Condições do acordo',
        content: contentConditionsOfTheAgreement,
        expanded: false,
      },
    ];

    return contentConditionsOfTheAgreement?.length > 0
      ? conditionsOfTheAgreement
      : [];
  };

  useEffect(() => {
    setAccordionItems([
      ...setAboutTheBillet(),
      ...setAboutTheAbatement(),
      ...setAboutAccountDebiting(),
      ...setConditionsOfTheAgreement(),
    ]);
  }, [debtData]);

  return {
    accordionItems,
  };
};
