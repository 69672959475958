import { ReactNode } from "react";

type DebitCardPriceInstallmentsProps = {
  children: ReactNode
};

const DebitCardPriceInstallments = ({ children }: DebitCardPriceInstallmentsProps) => {
  return (
    <div className="debit-card__installments">
      {children}
    </div>
  );
};

export default DebitCardPriceInstallments;
