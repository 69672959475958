export const faq = [
  {
    hasChevron: true,
    title: 'Quanto tempo leva para o meu pagamento ser reconhecido?',
    content: [
      <div key={'faq-1'}>
        <p>
          Um boleto demora até 3 dias úteis para ser confirmado pelo banco. Na
          Acordo Certo, a confirmação do seu pagamento também passa pela empresa
          parceira, por isso o tempo de compensação pode ser maior.
        </p>
        <p>
          Mas não se preocupe! Se você pagou o seu boleto dentro da data de
          vencimento, está tudo certo. Guarde o seu comprovante, ele é a prova
          do seu pagamento.
        </p>
        <p>
          Passou um prazo de 10 dias úteis e seu pagamento ainda não foi
          compensado? Envie seu comprovante através do{' '}
          <a href="https://www.acordocerto.com.br/formulario" target="_blank">
            fale conosco
          </a>{' '}
          para a gente verificar o que aconteceu e ajudar a resolver.
        </p>
      </div>,
    ],
  },
  {
    hasChevron: true,
    title: 'Onde e como pagar meu boleto?',
    content: [
      <div key={'faq-2'}>
        <p>
          Para realizar o pagamento do boleto, é possível copiar o código de
          barras clicando no botão “Copiar código de barras” e pagá-lo através
          do seu internet banking, baixar o boleto por meio do botão “Baixar
          boleto” contido nesta página ou em “Meus Acordos”, além da
          possibilidade de acessá-lo em seu e-mail, podendo ser pago em agências
          bancárias e casas lotéricas.
        </p>
        <p>
          Também, na hora de pagar, é importante verificar se você está
          realizando o pagamento ou apenas fazendo um agendamento (é comum
          confundir, por isso, certifique-se de que o pagamento será efetivado
          dentro do prazo estimado).
        </p>
        <p>
          Após o pagamento, guarde seu comprovante (ele é a prova do seu
          pagamento). O comprovante também pode ser solicitado futuramente pela
          empresa parceira, por isso, a gente recomenda que você mantenha
          guardado por até 5 anos.
        </p>
        <p>
          Todos os boletos emitidos pela Acordo Certo são gerados diretamente
          pela empresa parceira, que é o responsável pelo processamentos dos
          valores pagos por você.
        </p>
        <p>
          Atualmente todos os pagamentos dos acordos feitos devem ser pagos
          exclusivamente via boleto bancário.
        </p>
      </div>,
    ],
  },
  {
    hasChevron: true,
    title: 'Preciso alterar a data de vencimento do meu boleto, e agora?',
    content: [
      <div key={'faq-3'}>
        <p>
          Todas as condições de pagamento do seu acordo como descontos, números
          de parcelas e datas de vencimento são definidas pela empresa parceira.
          Por isso, a gente não consegue alterar a data de vencimento que consta
          em seu boleto.
        </p>
        <p>
          Antes de realizar um acordo, faça uma análise de suas reais
          possibilidades e priorize o pagamento do(s) seu(s) boleto(s) dentro da
          data de vencimento (assim, você não corre o risco de ter seu acordo
          cancelado e assim não perde as condições especiais oferecidas).
        </p>
        <p>
          Uma renegociação de dívida é como um compromisso. Para não passar pela
          quebra de acordo, é importante manter um bom planejamento financeiro.
        </p>
      </div>,
    ],
  },
  {
    hasChevron: true,
    title: 'Não consigo efetuar o pagamento do meu boleto, e agora?',
    content: [
      <div key={'faq-4'}>
        <p>
          Encontrou algum problema ao tentar efetuar o pagamento do seu boleto
          pelo seu banco, aplicativo, internet banking ou casas lotéricas? Não
          se preocupe, a gente vai te ajudar a resolver.
        </p>
        <p>
          - Primeiro, verifique se a tentativa de pagamento foi realizada dentro
          do prazo de vencimento do boleto;
        </p>
        <p>
          - Verifique também se o código de barras do seu boleto, como também
          seus dígitos, estão legíveis.
        </p>
        <p>
          Seu boleto pode levar de 30 segundos a 4 horas para ser registrado
          pelo banco. Por isso, se você tentou pagar antes deste prazo, tente
          novamente ao se passarem as 4 horas após a emissão do boleto.
        </p>
        <p>
          Se ainda assim você encontrar problemas para efetuar o pagamento,
          envie um e-mail através do{' '}
          <a href="https://www.acordocerto.com.br/formulario" target="_blank">
            fale conosco
          </a>{' '}
          com o seu boleto e informe qual foi o erro encontrado. Estamos aqui
          para te ajudar a resolver.{' '}
        </p>
      </div>,
    ],
  },
];
