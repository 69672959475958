import { ForwardRefRenderFunction, forwardRef } from 'react';

import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';

import { IconChevronRight } from '@consumidor-positivo/aurora';

import useAbatement, { AbatementModalRef } from './hooks/useAbatement';

import DebitCard from '@libs/atlas/components/DebitCard';

import './styles.scss';

const Abatement: ForwardRefRenderFunction<AbatementModalRef> = (_, ref) => {
  const {
    withAbatement,
    withoutAbatement,
    valueWithAbatement,
    valueWithoutAbatement,
    hasDiscount,
    discountPercentage,
    isOpen,
    handleClickOption,
    handleClose,
  } = useAbatement(ref);

  return (
    <Drawer
      className="without-separator"
      title="Como você quer negociar essa dívida?"
      isActive={isOpen}
      onClose={handleClose}
    >
      <Text
        as="p"
        variant="body-large"
        weight="regular"
        className="debt-modal__subtitle"
      >
        Selecione uma opção para continuar:
      </Text>

      <Conditional
        condition={withAbatement}
        renderIf={
          <button
            onClick={() => handleClickOption('with_abatement')}
            className="debt-modal__button"
          >
            <div>
              <p className="debt-modal__button__tag">
                Melhor oferta
              </p>
              <p className="debt-modal__button__title">Com abatimento</p>
              <p className="debt-modal__button__description">
                O valor total de sua dívida diminui e seu nome ficará limpo, mas
                não poderá contratar novos empréstimos e manter limite de
                crédito no Banco do Brasil.
              </p>
            </div>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">Valor atualizado para negociar:</p>
                <p className="debt-modal__button__value">
                  <span className="debt-modal__button__percentage">
                    <Conditional
                      condition={hasDiscount}
                      renderIf={
                        <DebitCard.PriceDiscount
                          value={discountPercentage}
                          customClass="abatement"
                        />
                      }
                    />
                  </span>
                  •&nbsp;&nbsp;&nbsp;{valueWithAbatement}
                </p>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />

      <Conditional
        condition={withoutAbatement}
        renderIf={
          <button
            onClick={() => handleClickOption('without_abatement')}
            className="debt-modal__button">
            <p className="debt-modal__button__title">Sem abatimento</p>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">Valor atualizado para negociar:</p>
                <p className="debt-modal__button__value">{valueWithoutAbatement}</p>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />
    </Drawer>
  );
};

export default forwardRef(Abatement);
