import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';

import { GetBilletResponseProps } from './types';

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
};

const useApiBillet = (msUrl: string) => {
  const isDev = import.meta.env.VITE_ENV === 'dev';
  const baseURL = isDev ? msUrl : import.meta.env.VITE_AC_API;
  const isGeminio = baseURL?.includes('geminio');
  const currHeaders = !isGeminio ? headers : {};

  const apiGenerateBillet = useRequest(
    (payload) => {
      const path = isGeminio
        ? '/api/v2/billet'
        : `marketplace/v1/app/paymentslip`;

      return acInterceptor({
        baseURL,
      }).Post<GetBilletResponseProps>(path, payload, {
        headers: currHeaders,
      });
    },
    {
      immediate: false,
    }
  );

  return {
    apiGenerateBillet,
  };
};

export default useApiBillet;
