/* import { StrictMode } from 'react'; */
import * as ReactDOM from 'react-dom/client';
import { RedlineProvider } from '@libs/redline';
import { SHProvider } from '@libs/sortinghat';
import {DogxProvider} from '@libs/dogx'

import App from './App';
import { createRedline } from 'redline-client-sdk';
import handlePreloadError from '@common/helpers/handlePreloadError';

window.addEventListener('vite:preloadError', handlePreloadError);

const rl = createRedline({
  writeKey: import.meta.env.VITE_REDLINE_WRITE_KEY,
  mode: import.meta.env.VITE_ENV,
  logs: false,
  domain: "ac",
  appName: import.meta.env.VITE_REDLINE_APP_NAME,
  appVersion: import.meta.env.VITE_DEPLOY_VERSION,
  autoTracking: {
    documentHide: false
  }
  /* nativeEvents: true, */
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <RedlineProvider
    instance={rl}
    appName={import.meta.env.VITE_REDLINE_APP_NAME}
  >
    <DogxProvider>
      <SHProvider
      appName="ac"
      mode={import.meta.env.VITE_ENV}
      debug={false}
      shKey={import.meta.env.VITE_SORTING_HAT_API_KEY}
      baseURL={import.meta.env.VITE_SORTING_HAT_API_URL}
      identifier="userDocument"
      maxCallNumber={1}
    >
      <App />
    </SHProvider>
    </DogxProvider>
  </RedlineProvider>
);
