import constants from '@common/constants';
import { useUserStore } from '@common/store/useUserStore';
import { SortingDebtResult } from '@common/apis/sorting-debt/types';
import { SimulatedDebt, TypeSelected } from '@common/apis/simulated-debt/types';
import useApiSimulatedDebt from '@common/apis/simulated-debt/useApiSimulatedDebt';

const useSimulatedDebt = () => {
  const user = useUserStore((state) => state.user);
  const { apiSimulatedDebt } = useApiSimulatedDebt();

  const getSimulatedDebtsInfo = async (msUrl: string, partnerId: string) => {
    const storadReferralId = localStorage.getItem('REFERRALID');
    const referralId = storadReferralId
      ? JSON.parse(storadReferralId)
      : undefined;

    const { dividas: simulatedDebts } = await apiSimulatedDebt.send({
      msUrl,
      documento: user?.documento,
      partnerSystemIdentifier: partnerId,
      referralId,
    });

    const safeDebts = simulatedDebts || []

    return safeDebts.map((debt) => {
      debt.includedContracts = [];
      debt.notIncludedContracts = [];
      debt.hideDebtValue = false;
      debt.debtCessionInfos += filterCessionDebtProperty(debt);

      debt.detalhes?.forEach((detail) => {
        if (detail.descricao && detail.descricao.indexOf('{') > -1) {
          detail.moreInfos = JSON.parse(detail.descricao);

          if (
            detail.moreInfos?.incluso === false ||
            detail.moreInfos?.included === false
          ) {
            debt.notIncludedContracts.push(detail);
          } else {
            debt.includedContracts.push(detail);
          }
        } else {
          debt.includedContracts.push(detail);
        }
      });

      const firstPaymentOption = debt?.opcaoPagamento?.[0]?.valorDividaOriginal;
      if (firstPaymentOption) {
        debt.opcaoPagamento.forEach((paymentOption) => {
          if (firstPaymentOption !== paymentOption.valorDividaOriginal) {
            debt.hideDebtValue = true;
          }
        });
      }

      return debt;
    });
  };

  const filterCessionDebtProperty = (debt: SimulatedDebt) => {
    const cessionDebtPropertyList = debt?.propriedades?.filter(
      ({ chave }) => chave === 'CESSAO'
    );

    if (cessionDebtPropertyList && cessionDebtPropertyList.length > 0) {
      return cessionDebtPropertyList[0];
    }
  };

  const findDebtByCondition = (
    debts: SimulatedDebt[],
    condition: (divida: SimulatedDebt) => boolean
  ) => debts.find(condition);

  const getCurrentSimulatedDebt = async (
    debt: SortingDebtResult,
    msUrl: string,
    partnerId: string
  ) => {
    const simulatedDebts = await getSimulatedDebtsInfo(msUrl, partnerId);

    const filteredById = findDebtByCondition(
      simulatedDebts,
      ({ id }) => id === debt.id
    );

    const filteredByValue = findDebtByCondition(
      simulatedDebts,
      ({ valor }) => valor === debt?.paymentOptions?.[0]?.currentDebtValue
    );

    const filteredByContract = findDebtByCondition(
      simulatedDebts,
      (divida) =>
        divida.contrato === debt.contract &&
        divida.credorId === debt.partnerId &&
        divida.sistemaId === debt.partnerSystemId
    );

    const simulatedDebt = simulatedDebts?.[0];
    const currentDebt =
      filteredById || filteredByValue || filteredByContract || simulatedDebt;

    if(!currentDebt) return

    currentDebt.expTime = new Date();
    currentDebt.partner = debt.partner;
    currentDebt.debtIsNegative = debt.debtIsNegative;

    const typeSelectedList = [
      'overdued_and_upcoming',
      'overdued_only',
      'with_abatement',
      'with_reenrolment',
      'without_reenrolment',
    ];

    if (
      debt.partner.name === 'Riachuelo' && // ????
      typeSelectedList.includes(currentDebt.identificador)
    ) {
      currentDebt.typeSelected = currentDebt.identificador as TypeSelected;
    }

    return currentDebt;
  };

  const checkExpiredInstallments = (debt: SimulatedDebt) => {
    const hasDebtTypeRiachuelo = (valorString: string) => {
      return debt?.opcaoPagamento.some(({ propriedades }) => {
        const optType = propriedades?.find(
          ({ chave }) =>
            chave === constants.riachuelo.keyRiachueloNegotiationType
        );

        return optType && optType?.valorString === valorString;
      });
    };

    const {
      valueStringOverduedAndUpcoming: overdueUpcoming,
      valueStringOverduedOnly: orverdueOnly,
    } = constants.riachuelo;

    const hasOverduedOnly = hasDebtTypeRiachuelo(orverdueOnly);
    const hasOverduedAndUpcoming = hasDebtTypeRiachuelo(overdueUpcoming);

    const extractOptionsByString = (valorString: string) => {
      return debt?.opcaoPagamento.filter(({ propriedades }) => {
        const optType = propriedades?.find(
          ({ chave }) =>
            chave === constants.riachuelo.keyRiachueloNegotiationType
        );
        return optType && optType?.valorString === valorString;
      });
    };

    const optionsOrverdueOnly = extractOptionsByString(orverdueOnly);
    const optionsOverdueUpcoming = extractOptionsByString(overdueUpcoming);

    return {
      optionsOrverdueOnly,
      optionsOverdueUpcoming,
      hasOverduedOnly,
      hasOverduedAndUpcoming,
    };
  };

  const checkReenrolment = (debt: SimulatedDebt) => {
    const hasKrotonReenrolment = (valorBoolean: boolean) => {
      return debt?.opcaoPagamento?.some(({ propriedades }) => {
        const optType = propriedades?.find(
          ({ chave }) => chave === 'IS_REMATRICULA_INCLUDED'
        );

        return optType && optType?.valorBoolean === valorBoolean;
      });
    };

    const hasWithoutReenrolment = hasKrotonReenrolment(false);
    const hasWithReenrolment = hasKrotonReenrolment(true);

    const extractOptionsByBoolean = (valorBoolean: boolean) => {
      return debt?.opcaoPagamento?.filter(({ propriedades }) => {
        const optType = propriedades?.find(
          ({ chave }) => chave === 'IS_REMATRICULA_INCLUDED'
        );
        return optType && optType?.valorBoolean === valorBoolean;
      });
    };

    const optionsWithReenrolment = extractOptionsByBoolean(true);
    const optionsWithoutReenrolment = extractOptionsByBoolean(false);

    return {
      optionsWithReenrolment,
      optionsWithoutReenrolment,
      hasWithoutReenrolment,
      hasWithReenrolment,
    };
  };

  const checkAbatement = (debt: SimulatedDebt) => {
    const isBancoBrasil = debt?.partner?.partnerIdentifier === 'bancodobrasil';
    const hasBBAbatement = debt?.opcaoPagamento?.find(
      (option) => option?.percentualDesconto > 0
    );

    return isBancoBrasil && !!hasBBAbatement;
  };

  return {
    checkAbatement,
    checkExpiredInstallments,
    checkReenrolment,
    getCurrentSimulatedDebt,
    getSimulatedDebtsInfo,
  };
};

export default useSimulatedDebt;
