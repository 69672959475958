import classNames from 'classnames';
import { Spinetic, SpineticItem } from 'react-spinetic';
import Text from '@libs/atlas/components/Text';
import { Button } from '@consumidor-positivo/aurora';
import Conditional from '@libs/atlas/components/misc/Conditional';

import useGetOffers from '@common/hooks/useOffers';
import { useUserStore } from '@common/store/useUserStore';

import { useOfferCarousel } from './hooks';
import { offerCarouselConfig } from './config';
import { OfferCarouselProps } from './types';
import { OfferCarouselCard } from './components/Card';
import { OfferCarouselCardPlaceholder } from './components/CardPlaceholder';
import { OfferModalDigio } from '../OfferModal/Digio';
import { OfferEmptyState } from '../OfferEmptyState';
import './styles.scss';

const OfferCarousel = ({
  offers,
  location,
  showOffersCTA = false,
  onClickOffersCTA,
  offersCTAOnTop = false,
  lightTheme = false,
  blockTitle,
  style,
  decideFinish = true,
  bottomOffersCTA = 'Quero ver mais ofertas',
}: OfferCarouselProps) => {
  const { changeCarousel, onChangeIndexCarousel } = useOfferCarousel(offers);
  const {
    onClickOffer,
    redirectToOffers,
    onCloseDigioModal,
    openDigioModal,
    shLoading,
    noOffer,
  } = useGetOffers();

  const user = useUserStore((state) => state.user);
  const title = blockTitle
    ? blockTitle
    : `${user.primeiroNome}, essas ofertas são ideais pra você`;

  const blockStyle = classNames('offers-featured', {
    'offers-featured--light': lightTheme,
  });

  if (shLoading)
    return (
      <section className={blockStyle}>
        <div className="offers-featured__container">
          <OfferCarouselCardPlaceholder />
        </div>
      </section>
    );

  if (noOffer)
    return (
      <section className={blockStyle}>
        <div className="offers-featured__container">
          <OfferEmptyState />
        </div>
      </section>
    );

  return (
    <>
      <section className={blockStyle} style={style}>
        <div className="offers-featured__container">
          <div className="offers-featured__container-title">
            <Text
              as="h2"
              variant="heading-four"
              className="offers-featured__title"
              color={lightTheme ? 'common' : 'white'}
            >
              {title}
            </Text>

            <Conditional
              condition={showOffersCTA && offersCTAOnTop}
              renderIf={
                <div className="offers-featured__cta-btn">
                  <Button
                    type="outlined"
                    onClick={() => {
                      redirectToOffers();
                      onClickOffersCTA && onClickOffersCTA();
                    }}
                  >
                    Conhecer mais ofertas
                  </Button>
                </div>
              }
            />
          </div>

          <Conditional
            condition={decideFinish}
            renderIf={
              <div className="offers-featured__list">
                <Spinetic
                  config={offerCarouselConfig}
                  change={onChangeIndexCarousel}
                >
                  {offers?.slice(0, 2).map((offer, index) => (
                    <SpineticItem key={index}>
                      <OfferCarouselCard
                        offer={offer}
                        index={index}
                        location={location}
                        changeCarousel={changeCarousel}
                        onClickOffer={() =>
                          onClickOffer(offer, location, index)
                        }
                      />
                    </SpineticItem>
                  ))}
                </Spinetic>
              </div>
            }
          />
        </div>

        <Conditional
          condition={showOffersCTA && !offersCTAOnTop}
          renderIf={
            <div className="offers-featured__cta-btn">
              <Button
                type="outlined"
                negative
                onClick={() => {
                  redirectToOffers();
                  onClickOffersCTA && onClickOffersCTA();
                }}
              >
                {bottomOffersCTA}
              </Button>
            </div>
          }
        />
      </section>
      <OfferModalDigio
        location={location}
        visible={openDigioModal}
        onCloseModal={onCloseDigioModal}
      />
    </>
  );
};

export default OfferCarousel;
