import Accordion from '@libs/atlas/components/Accordion';
import Text from '@libs/atlas/components/Text';
import Button from '@libs/atlas/components/Button';
import Conditional from '@libs/atlas/components/misc/Conditional';

import { IconChevronRight } from '@libs/atlas/components/icons/default';

import DebtsModal from '@common/components/DebtsModal';
import { DebtData } from '@common/hooks/useDebts/types';
import {
  SimulatedDebt,
  SimulatedPaymentOption,
  DebtBankAccount,
} from '@common/apis/simulated-debt/types';

import { useConfirmAgreementDetails } from './hooks';

import './styles.scss';
interface ConfirmAgreementDetails {
  debt: SimulatedDebt;
  paymentOption: SimulatedPaymentOption;
  debtData: DebtData;
  selectedBankAccount?: DebtBankAccount;
}

const ConfirmAgreementDetails = ({
  debt,
  paymentOption,
  debtData,
  selectedBankAccount,
}: ConfirmAgreementDetails) => {
  const {
    itemDetails,
    isDebtComposition,
    hasCetProps,
    hasInfosCielo,
    modalDetailsRef,
    clickOpenModal,
  } = useConfirmAgreementDetails({
    debt,
    paymentOption,
    debtData,
    selectedBankAccount,
  });

  const detailsAgreement = [
    {
      isOpen: false,
      title: 'Informações importantes',
      content: (
        <>
          <Conditional
            condition={!!itemDetails}
            renderIf={
              <>
                <ul className="confirm-agreement-details__detail">
                  {itemDetails?.map((detail: any, i: number) => {
                    return (
                      <li key={`detail-${Math.random() * i}`}>
                        <Text
                          as="h3"
                          className="confirm-agreement-details__title"
                        >
                          {detail.title}
                        </Text>

                        <Text className="confirm-agreement-details__desc">
                          {detail.desc}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
                <Conditional
                  condition={
                    !!debt?.typeSelected &&
                    debt?.typeSelected === 'overdued_only' &&
                    !!debt?.installmentsRange?.overdueOnlyRange
                  }
                  renderIf={
                    <Text
                      weight="regular"
                      color="medium"
                      className="confirm-agreement-details__overdue"
                    >
                      Você está renegociando apenas as parcelas vencidas (
                      {debt?.installmentsRange?.overdueOnlyRange}).
                    </Text>
                  }
                />
                <Conditional
                  condition={
                    !!debt?.typeSelected &&
                    debt?.typeSelected === 'overdued_and_upcoming' &&
                    !!debt?.installmentsRange?.overdueOnlyRange &&
                    !!debt?.installmentsRange?.overdueUpcomingRange
                  }
                  renderIf={
                    <Text
                      weight="regular"
                      color="medium"
                      className="confirm-agreement-details__overdue"
                    >
                      Você está renegociando todo o contrato: parcelas vencidas{' '}
                      ({debt?.installmentsRange?.overdueOnlyRange}) e parcelas
                      futuras ({debt?.installmentsRange?.overdueUpcomingRange}).
                    </Text>
                  }
                />
              </>
            }
          />

          <Conditional
            condition={isDebtComposition}
            renderIf={
              <div className="confirm-agreement-details__composition">
                <div className="division-card-dashed"></div>
                <Text as="h3" className="confirm-agreement-details__title">
                  Aviso
                </Text>
                <Text className="confirm-agreement-details__desc">
                  Essa dívida é composta por mais de um produto/contrato.
                </Text>
              </div>
            }
          />

          <Conditional
            condition={hasCetProps || isDebtComposition || hasInfosCielo}
            renderIf={
              <Button
                btnText
                className="confirm-agreement-details__btn-open-modal"
                onClick={() => clickOpenModal()}
              >
                {` ${
                  isDebtComposition
                    ? 'Ver composição da dívida'
                    : 'Ver mais detalhes do acordo'
                } `}
                <IconChevronRight />
              </Button>
            }
          />
        </>
      ),
    },
  ];

  return (
    <section className="confirm-agreement-details">
      <Accordion items={detailsAgreement} />
      <DebtsModal.Details ref={modalDetailsRef} />
    </section>
  );
};

export default ConfirmAgreementDetails;
