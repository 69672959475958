import { ReactNode } from 'react';
import classNames from 'classnames';

type DebitCardDescriptionProps = {
  children?: ReactNode;
  type?: 'price';
  noWordWrap?: boolean;
};

const DebitCardDescription = ({ children, type, noWordWrap }: DebitCardDescriptionProps) => {
  return (
    <div
      className={classNames("debit-card__desc", {
        [`debit-card__desc--${type}`]: !!type,
        [`debit-card__desc--word-wrap`]: noWordWrap,
      })}
    >
      {children}
    </div>
  );
};

export default DebitCardDescription;
