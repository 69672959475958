import { useEffect, useState } from 'react';
import useApiFirebolt from '@common/apis/firebolt/useApiFirebolt';
import { useUserStore } from '@common/store/useUserStore';
import { isValidBirthdate } from '@libs/utils/validators/birthdate';
import { FormInstance } from 'houseform';
import useTrackOffer from '@common/hooks/useTrackOffer';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';

type stepFormType = 'birthdate' | 'next' | 'loading' | 'success';

export function useOfferModalDigio(visible: boolean, location: string) {
  const { sendSoftProposal } = useApiFirebolt();
  const { trackProposalStarted } = useTrackOffer();
  const user = useUserStore((state) => state.user);

  const [stepForm, setStepForm] = useState<stepFormType>('birthdate');
  const [openModal, setOpenModal] = useState(false);
  const digioSlug = 'cards-digio'

  async function validateBirthdateField(value: string, form: FormInstance) {
    const result = isValidBirthdate.safeParse(value);
    const isNotEmpty = value !== '';
    const isTouched = form.getFieldValue('birthdate')?.isTouched;
    const isDirty = form.getFieldValue('birthdate')?.isDirty;

    if (!result.success && isTouched && isDirty) {
      throw JSON.parse(result?.error.message)[0].message;
    }

    if (result.success && isNotEmpty) setStepForm('next');
    return result.success;
  }

  async function handleProposal(e: { birthdate: string }) {
    setStepForm('loading');

    const payload = {
      item: {
        cpf: user?.documento,
        full_name: user?.nome,
        email: user?.email,
        main_phone: user?.celular,
        date_of_birth: e?.birthdate,
        choosen_card: 'digio',
        issuer: 'digio',
        product_slug: digioSlug,
        partner: 'ac',
        application: 'ac',
      },
      metadata: {
        fbCookies: {
          fcbId: getFbAvailableCookies().fbc,
          fbpId: getFbAvailableCookies().fbp,
        },
        gaCookies: {
          gaId: getGAAvailableCookies().ga,
        },
      },
    };

    await sendSoftProposal.send('digio', payload).then((res) => {
      if (!res?.webhookResult?.preventContinue) {
        const payload = {
          initialSlug: digioSlug,
          finalSlug: digioSlug,
          category: 'cards',
          redirectUrl: window.location.href,
          location,
          fireboltId: res.firebolt_id,
        };
        trackProposalStarted(payload);
        setStepForm('success');
      }
    });
  }

  function onCloseModal() {
    setOpenModal(false);
  }

  useEffect(() => setOpenModal(visible), [visible]);

  return {
    openModal,
    onCloseModal,
    stepForm,
    validateBirthdateField,
    handleProposal,
  };
}
