import { currencyToLocale } from "@libs/utils/helpers/currencyToLocale";

const propertiesDescriptionsCET = {
  PERCENTUAL_CUSTO_EFETIVO_MES: "CET mensal: ",
  PERCENTUAL_CUSTO_EFETIVO_ANO: "CET anual: ",
  TAXA_JUROS: "Taxa de Juros: ",
  INTEREST_RATE: "Taxa de Juros: ",
  TAXA_JUROS_MENSAL: "Juros mensal: ",
  TAXA_JUROS_ANUAL: "Juros anual: ",
  CUSTO_EFETIVO_PROPOSTA: "Valor total: ",
  VALOR_IOF: "IOF: ",
} as { [key: string]: string };


const formatPercentage = (number: number) => { // TODO: Move to utils ??
  const parts = String(number).split('.');
  const integerPart = parts[0];
  const decimalPart = parts?.length > 1 ? parts[1].slice(0, 2) : '';

  const formattedNumber = integerPart + (decimalPart ? '.' + decimalPart : '');

  return `${formattedNumber} %`;
}

export const useFormattedCetProps = (paymentOption: any) => {
  const { propriedades, properties, paymentOptionProperties } = paymentOption || {};
  const currentProps = propriedades || properties || paymentOptionProperties || [];

  const formattedProperties = currentProps.reduce((acc: any, prop: any) => {
    const currentValueString = prop?.valorString || prop?.stringValue;
    const valueString = currentValueString ? `R$ ${currentValueString}` : currentValueString;

    const currentValueDecimal = prop?.valorDecimal || prop?.decimalValue;
    const valueDecimal = currentValueDecimal ? formatPercentage(currentValueDecimal) : currentValueDecimal;

    const currentKey = prop?.chave || prop?.key;
    const hasValue = !!valueString || !!valueDecimal
    acc[currentKey] = {
      title: hasValue ? propertiesDescriptionsCET[currentKey] : undefined,
      valueString: valueString,
      valueDecimal: valueDecimal,
    };

    if (currentKey === "VALOR_PARCELA_APOS_VENCIMENTO") {
      acc["VALOR_PARCELA_APOS_VENCIMENTO"] = {
        title: "Valor após vencimento: ",
        valueDecimal: currencyToLocale(currentValueDecimal),
      };
    }

    if (currentKey === "DESCRICAO_IOF") {
      acc["VALOR_IOF"] = {
        ...acc["VALOR_IOF"],
        descriptionIOF: currentValueString,
      };
    }

    return acc;
  }, {});

  const hasCetProp = Object.values(formattedProperties).some((value: any) => value.title);

  return hasCetProp ? formattedProperties : false
};
