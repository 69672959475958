import { useRequest } from 'alova';

import acInterceptor from '../acInterceptor';

import {
  GetPixCodeResponseProps,
  GetPixPaymentStatusResponseProps,
  GetQRCodeResponseProps,
} from './types';

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
};

const useApiPix = (msUrl?: string) => {
  const baseURL = msUrl ? msUrl : import.meta.env.VITE_AC_PIXLETO_API_URL;
  const isGeminio = baseURL?.includes('geminio');
  const currHeaders = !isGeminio ? headers : {};

  const apiGetPixInternal = useRequest(
    ({ installmentCode }) => {
      return acInterceptor({
        baseURL,
      }).Get<GetPixCodeResponseProps>(`/v1/pix/${installmentCode}`, {
        headers: currHeaders,
      });
    },
    {
      immediate: false,
    }
  );

  const apiGeneratePixInternal = useRequest(
    (payload) => {
      return acInterceptor({
        baseURL,
      }).Post<GetQRCodeResponseProps>('/v1/pix/qrcode', payload, {
        headers: currHeaders,
      });
    },
    {
      immediate: false,
    }
  );

  const apiGeneratePixExternal = useRequest(
    (msUrl, payload) =>
      acInterceptor({ baseURL: msUrl }).Post<GetQRCodeResponseProps>(
        `/pix`,
        payload,
        {
          headers: currHeaders,
        }
      ),
    {
      immediate: false,
    }
  );

  const apiCheckPixPaymentStatus = useRequest(
    ({ installmentCode }) => {
      const path = isGeminio
        ? '/payment/v1/pix/status'
        : `/v1/pix/${installmentCode}`;

      return acInterceptor({
        baseURL,
      }).Get<GetPixPaymentStatusResponseProps>(path, {
        headers: currHeaders,
      });
    },
    {
      immediate: false,
    }
  );

  return {
    apiGetPixInternal,
    apiGeneratePixInternal,
    apiCheckPixPaymentStatus,
    apiGeneratePixExternal,
  };
};

export default useApiPix;
