import { Text } from '@consumidor-positivo/aurora';
import classNames from 'classnames';

interface OrientationCardProps {
  hasDebitAccount: boolean;
  pix: boolean;
}

const OrientationCard = ({ hasDebitAccount, pix }: OrientationCardProps) => {
  const withoutPaymentData = [
    'Até amanhã, o boleto vai ser enviado para você por e-mail. Você também pode localizá-lo em “Meus Acordos”, aqui no site da Acordo Certo.',
    'Com o boleto em mãos, fique atento à data de vencimento.',
    'Para pagar online, acesse um app de pagamentos ou do banco e escaneie o código de barras do boleto. Para pagar em bancos ou caixa eletrônico, será necessário imprimir o boleto.',
  ];
  const pixWithoutPaymentData = [
    'Até amanhã, o código Pix vai ser enviado para você por e-mail. Você também pode localizá-lo em “Meus Acordos”, aqui no site da Acordo Certo.',
    'Com o código do pix em mãos, fique atento à data de vencimento.',
    'Acesse seu Internet Banking ou app de pagamentos.',
    'Vá na área do Pix, e dentro de Pix procure a opção QR Code.',
    'Escolha a opção: Pagar um QR Code e realize o pagamento.',
  ];

  const debitAccountData = [
    'Garanta que o valor da parcela esteja na conta cadastrada, antes do vencimento.',
    'No dia do vencimento, o valor será debitado da sua conta de forma automática.',
    'Pronto, menos uma dívida ou parcela para você!',
  ];

  const withoutPaymentInfo = pix ? pixWithoutPaymentData : withoutPaymentData;
  const data = hasDebitAccount ? debitAccountData : withoutPaymentInfo;

  return (
    <div
      className={classNames('closed-agreement-page-v2__orientation', {
        'closed-agreement-page-v2__orientation--without-payment-data':
          !hasDebitAccount,
      })}
    >
      <Text variant="heading-micro" weight="bold">
        Orientações
      </Text>
      <ol>
        {data.map((item) => (
          <li key={`orientation-${item}`}>{item}</li>
        ))}
      </ol>
    </div>
  );
};

export default OrientationCard;
