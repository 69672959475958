import Abatement from './Abatement';
import Details from './Details';
import ExpiredInstallments from './ExpiredInstallments';
import LeadRedirect from './LeadRedirect';
import Negative from './Negative';
import Reenrolment from './Reenrolment';
export default {
  Abatement,
  Details,
  ExpiredInstallments,
  LeadRedirect,
  Negative,
  Reenrolment,
  };
