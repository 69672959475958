import step1 from '@dues/assets/images/illustration-tutorial-step-01.svg';
import step2 from '@dues/assets/images/illustration-tutorial-step-02.svg';
import step3 from '@dues/assets/images/illustration-tutorial-step-03.svg';

interface useCloseAgreementTutorialProps {
  firstInstallmentDate: string;
}

const useClosedAgreementTutorial = ({
  firstInstallmentDate,
}: useCloseAgreementTutorialProps) => {
  const steps = [
    {
      step: 1,
      text: `Efetue o pagamento do boleto referente à <strong>primeira parcela até ${firstInstallmentDate}</strong>.`,
      image: step1,
    },
    {
      step: 2,
      text: 'Tenha atenção ao vencimento das próximas parcelas e acompanhe através da área: <strong>meus acordos</strong>.',
      image: step2,
    },
    {
      step: 3,
      text: 'Efetue o pagamento das parcelas restantes e <strong>livre-se das dívidas</strong> de uma vez por todas!',

      image: step3,
    },
  ];

  return {
    steps,
  };
};

export default useClosedAgreementTutorial;
