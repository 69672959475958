import Text from '@libs/atlas/components/Text';
import { subtitleContent, titleContent } from '../../data';
import { TitleSectionProps } from '../../../types';
import './styles.scss';

const TitleSection = ({ paymentStatus }: TitleSectionProps) => {
  return (
    <div className="title-section">
      <Text
        as="h1"
        variant="heading-one"
        className="title-section__title"
        dangerouslySetInnerHTML={titleContent[paymentStatus]}
      />
      <Text
        as="span"
        weight="regular"
        className="title-section__subtitle"
        dangerouslySetInnerHTML={subtitleContent[paymentStatus]}
      />
    </div>
  );
};
export default TitleSection;
