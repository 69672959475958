import { ForwardRefRenderFunction, forwardRef } from 'react';

import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { IconChevronRight, LazyImage } from '@consumidor-positivo/aurora';
import fireEmoji from '@libs/atlas/assets/icons/fire-emoji.svg';

import useExpiredInstallments, {
  ExpiredInstallmentsModalRef,
} from './hooks/useExpiredInstallments';
import './styles.scss';

const ExpiredInstallments: ForwardRefRenderFunction<
  ExpiredInstallmentsModalRef
> = (_, ref) => {
  const {
    isOpen,
    hasOverduedOnly,
    hasOverduedAndUpcoming,
    titleOverdueUpcoming,
    valueOrverdueOnly,
    valueOverdueUpcoming,
    handleClickOption,
    handleClose,
    optionsToSelect,
    overdueUpcomingDescription,
    praDesc,
    originalDebtValues,
    discounts,
  } = useExpiredInstallments(ref);

  return (
    <Drawer
      className="without-separator"
      title="Como você quer negociar essa dívida?"
      isActive={isOpen}
      onClose={handleClose}
    >
      <Text
        as="p"
        variant="body-large"
        weight="regular"
        color="medium"
        className="debt-modal__subtitle"
      >
        Selecione uma opção para continuar:
      </Text>

      <Conditional
        condition={hasOverduedOnly}
        renderIf={
          <button
            onClick={() => handleClickOption(optionsToSelect.overdueOnly)}
            className="debt-modal__button"
          >
            <div>
              <p className="debt-modal__button__tag">Recomendado</p>
              <p className="debt-modal__button__title">
                Apenas parcelas vencidas
              </p>
              <Conditional
                condition={!!praDesc?.overdueOnlyRange}
                renderIf={
                  <p className="debt-modal__button__description">
                    Negocie apenas as parcelas {praDesc.overdueOnlyRange}.
                  </p>
                }
              />
            </div>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">
                  Valor atualizado para negociar:
                </p>
                <div className="debt-modal__button__value">
                  <Conditional
                    condition={!!discounts.overdueOnly}
                    renderIf={
                      <>
                        <span className="debt-modal__button__discount">
                          <LazyImage
                            lazy={false}
                            height={15}
                            width={13}
                            src={fireEmoji}
                          />
                          {discounts.overdueOnly}
                        </span>
                        <span>•</span>
                      </>
                    }
                  />
                  <Conditional
                    condition={
                      originalDebtValues.overdueOnly !== valueOrverdueOnly
                    }
                    renderIf={
                      <Text
                        weight="regular"
                        color="medium"
                        className="debt-modal__button__stripe"
                      >
                        {originalDebtValues.overdueOnly}
                      </Text>
                    }
                  />
                  <Text weight="bold" color="medium">
                    {valueOrverdueOnly}
                  </Text>
                </div>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />

      <Conditional
        condition={hasOverduedAndUpcoming}
        renderIf={
          <button
            onClick={() => handleClickOption(optionsToSelect.overdueUpcoming)}
            className="debt-modal__button"
          >
            <div>
              <p className="debt-modal__button__title">
                {titleOverdueUpcoming}
              </p>
              <p className="debt-modal__button__description">
                {overdueUpcomingDescription}
              </p>
            </div>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">
                  Valor atualizado para negociar:
                </p>

                <div className="debt-modal__button__value">
                  <Conditional
                    condition={!!discounts.overdueUpcoming}
                    renderIf={
                      <>
                        <span className="debt-modal__button__discount">
                          <LazyImage
                            lazy={false}
                            height={15}
                            width={13}
                            src={fireEmoji}
                          />
                          {discounts.overdueUpcoming}
                        </span>
                        <span>•</span>
                      </>
                    }
                  />
                  <Conditional
                    condition={
                      originalDebtValues.overdueUpcoming !==
                      valueOverdueUpcoming
                    }
                    renderIf={
                      <Text
                        weight="regular"
                        color="medium"
                        className="debt-modal__button__stripe"
                      >
                        {originalDebtValues.overdueUpcoming}
                      </Text>
                    }
                  />
                  <Text weight="bold" color="medium">
                    {valueOverdueUpcoming}
                  </Text>
                </div>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />
    </Drawer>
  );
};

export default forwardRef(ExpiredInstallments);
