import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const testCookieName = 'dx-api-pix';

const VARIANTS = {
  A: 'a-without-api-pix',
  B: 'b-new-api-pix'
};

const useApiPixExperiment = () => {
  const currentResult = getCookie(testCookieName, false);

  return {
    resultIsNewApiPix: !!currentResult?.includes(VARIANTS.B)
  };
};

export default useApiPixExperiment;
