import DebitCardAppend from './Append';
import DebitCardContract from './Contract';
import DebitCardDetails from './Details';
import DebitCardDescription from './Description';
import DebitCardHeader from './Header';
import DebitCardNegativedFlag from './NegativedFlag';
import DebitBanner from './Banner';
import DebitCardPrice from './Price';
import DebitCardPriceDiscount from './PriceDiscount';
import DebitCardPriceInstallments from './PriceInstallments';
import DebitCardPriceLine from './PriceLine';
import DebitCardText from './Text';
import DebitCardRoot from './Root';
import DebitSkeleton from './Skeleton';

import './styles.scss';

export default {
  Append: DebitCardAppend,
  Contract: DebitCardContract,
  Details: DebitCardDetails,
  Description: DebitCardDescription,
  Header: DebitCardHeader,
  NegativedFlag: DebitCardNegativedFlag,
  Banner: DebitBanner,
  Price: DebitCardPrice,
  PriceDiscount: DebitCardPriceDiscount,
  PriceInstallments: DebitCardPriceInstallments,
  PriceLine: DebitCardPriceLine,
  Root: DebitCardRoot,
  Text: DebitCardText,
  Skeleton: DebitSkeleton,
};
