import { ForwardRefRenderFunction, forwardRef } from 'react';

import Drawer from '@libs/atlas/components/Drawer';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';

import { IconChevronRight } from '@consumidor-positivo/aurora';

import useReenrolment, { ReenrolmentModalRef } from './hooks/useReenrolment';

import './styles.scss';

const Reenrolment: ForwardRefRenderFunction<ReenrolmentModalRef> = (_, ref) => {
  const {
    hasWithReenrolment,
    hasWithoutReenrolment,
    valueWithReenrolment,
    valueWithoutReenrolment,
    isOpen,
    handleClickOption,
    handleClose,
  } = useReenrolment(ref);

  return (
    <Drawer
      className="without-separator"
      title="Como você quer negociar essa dívida?"
      isActive={isOpen}
      onClose={handleClose}
    >
      <Text
        as="p"
        variant="body-large"
        weight="regular"
        className="debt-modal__subtitle"
      >
        Selecione uma opção para continuar:
      </Text>

      <Conditional
        condition={hasWithReenrolment}
        renderIf={
          <button
            onClick={() => handleClickOption('with_reenrolment')}
            className="debt-modal__button"
          >
            <div>
              <p className="debt-modal__button__tag">
                Melhor oferta
              </p>
              <p className="debt-modal__button__title">
                Negociar com rematrícula
              </p>
            </div>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">Valor atualizado para negociar:</p>
                <p className="debt-modal__button__value">
                  {valueWithReenrolment}
                </p>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />

      <Conditional
        condition={hasWithoutReenrolment}
        renderIf={
          <button
            onClick={() => handleClickOption('without_reenrolment')}
            className="debt-modal__button"
          >
            <p className="debt-modal__button__title">
              Negociar sem rematrícula
            </p>
            <div className="debt-modal__button__holder">
              <div>
                <p className="debt-modal__button__description">Valor atualizado para negociar:</p>
                <p className="debt-modal__button__value">{valueWithoutReenrolment}</p>
              </div>
              <div className="debt-modal__button__icon">
                <IconChevronRight />
              </div>
            </div>
          </button>
        }
      />
    </Drawer>
  );
};

export default forwardRef(Reenrolment);
