
// This file is generated automatically
// To edit see the file libs/atlas/scr/tasks/generateIcons.js
import Icon from "../index";

export default function IconX(props: any) {
  return (
    <Icon
      iconMarkup={"<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M19.6653 4.33474C20.1116 4.78105 20.1116 5.50467 19.6653 5.95098L5.95098 19.6653C5.50467 20.1116 4.78105 20.1116 4.33474 19.6653C3.88842 19.219 3.88842 18.4953 4.33474 18.049L18.049 4.33474C18.4953 3.88842 19.219 3.88842 19.6653 4.33474Z' fill='currentColor'></path><path fill-rule='evenodd' clip-rule='evenodd' d='M4.33474 4.33474C4.78105 3.88842 5.50467 3.88842 5.95098 4.33474L19.6653 18.049C20.1116 18.4953 20.1116 19.219 19.6653 19.6653C19.219 20.1116 18.4953 20.1116 18.049 19.6653L4.33474 5.95098C3.88842 5.50467 3.88842 4.78105 4.33474 4.33474Z' fill='currentColor'></path></svg>"}
      iconName="IconX"
      iconSize={props.size}
      iconColor={props.color}
      {...props}
    />
  );
}

