import { useImperativeHandle, useState } from 'react';
import { SortingDebtResult } from '@common/apis/sorting-debt/types';

interface OpenProps {
  debt: SortingDebtResult;
  buttonText?: string;
  closeModalOnClickCta?: boolean;
  onClickNegotiate: () => void;
}

export interface NegativeModalRef {
  open: (args: OpenProps) => void;
  loading: (loading: boolean) => void;
}

const useNegative = (ref: React.ForwardedRef<NegativeModalRef>) => {

  const [currentDebt, setCurrentDebt] = useState<SortingDebtResult | null>(null);
  const [buttonText, setButtonText] = useState<string>("Negociar")
  const [closeModalOnClickCta, setCloseModalOnClickCta] = useState<boolean | undefined>(false);
  const [clickCta, setClickCta] = useState<(() => void) | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setCurrentDebt(null);
    setClickCta(null);
  };

  const handleClick = () => {
    if (clickCta) {
      clickCta();
    }

    if(closeModalOnClickCta) setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open({ debt, onClickNegotiate, buttonText = "Negociar", closeModalOnClickCta }: OpenProps) {

      setCurrentDebt(debt);
      setButtonText(buttonText);
      setCloseModalOnClickCta(closeModalOnClickCta)

      setClickCta(() => onClickNegotiate);

      setIsOpen(true);
    },
    loading(loading) {
      setIsLoading(loading);
    }
  }));

  return {
    currentDebt,
    isOpen,
    isLoading,
    handleClick,
    handleClose,
    buttonText
  };
};

export default useNegative;
