import acInterceptor from '../acInterceptor';
import { useRequest } from 'alova';

import { SimulatedDebtResponse, SimulatedDebtRequest } from './types';
import { transformPayload } from './transformPayload';

const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
};

const useApiSimulatedDebt = () => {
  const requestHandler = (payload: SimulatedDebtRequest) => {
    return acInterceptor({
      baseURL: payload.msUrl,
    }).Post<SimulatedDebtResponse>(
      '/api/divida/buscar',
      transformPayload(payload),
      {
        headers: headers,
      }
    );
  };

  const apiSimulatedDebt = useRequest(requestHandler, {
    immediate: false,
    retry: 5,
  });

  return { apiSimulatedDebt };
};

export default useApiSimulatedDebt;
