import React from 'react';

import Message from '@libs/atlas/components/Message';
import { IconNegotiation } from '@libs/atlas/components/icons/default';

import './styles.scss';
import { DebtData } from '@common/hooks/useDebts/types';

type MessageType =
  | 'overdued_only'
  | 'overdued_and_upcoming'
  | 'with_abatement'
  | 'with_reenrolment';

interface PropsWarningNegotiation {
  typeSelected?: MessageType;
  currentOptionHasEntry?: boolean;
  currentOptionHasDebtAcc?: boolean;
  currentOptionHasAboutPix?: boolean;
  debtData?: DebtData;
}

const messageTypeSelected: Record<MessageType, string> = {
  overdued_only: 'apenas parcelas vencidas',
  overdued_and_upcoming: 'parcelas vencidas e futuras',
  with_abatement: 'parcelas com abatimentos',
  with_reenrolment: 'com a rematrícula',
};

const WarningNegotiation: React.FC<PropsWarningNegotiation> = ({
  typeSelected,
  currentOptionHasEntry,
  currentOptionHasDebtAcc,
  currentOptionHasAboutPix,
  debtData,
}) => {
  const { isItau, isCarrefour } = debtData?.currentPartner || {};

  const { isDebitInAccountAndNotBradesco, hasDebitInAccountEntry } =
    debtData?.paymentMeth || {};

  const { hasEntry } = debtData?.paymentOpt || {};

  return (
    <>
      {typeSelected && (
        <Message
          type="custom"
          customClass="negotiation--center"
          icon={<IconNegotiation iconColor="dark" />}
        >
          <p>
            Você está negociando{' '}
            <strong>{messageTypeSelected[typeSelected]}</strong>
          </p>
        </Message>
      )}

      {currentOptionHasEntry && (
        <Message
          type="warning"
          customClass="negotiation"
          icon={<IconNegotiation iconColor="dark" />}
        >
          <p>
            Você <strong>pagará a entrada uma única vez,</strong> antes de
            começar a pagar as parcelas.
          </p>
        </Message>
      )}

      {currentOptionHasDebtAcc && (
        <Message
          type="warning"
          customClass="negotiation"
          icon={<IconNegotiation iconColor="dark" />}
        >
          {isDebitInAccountAndNotBradesco || hasDebitInAccountEntry ? (
            <p>
              Após efetuar o pagamento da
              {hasEntry ? ' entrada ' : ' primeira parcela '}
              conforme o acordo estabelecido, o débito das parcelas seguintes
              será realizado automaticamente na data de vencimento, desde que
              haja saldo disponível em sua conta ou limite de cheque especial.
            </p>
          ) : (
            <p>
              No dia do vencimento, o débito ocorre de forma automática na sua
              conta, caso tenha dinheiro disponível na conta ou limite de
              cheque-especial.
            </p>
          )}
        </Message>
      )}

      {currentOptionHasAboutPix && isItau && (
        <Message
          type="warning"
          customClass="negotiation"
          icon={<IconNegotiation iconColor="dark" />}
        >
          <p>
            Após efetuar o{' '}
            <strong>pagamento da primeira parcela via Pix,</strong> as{' '}
            <strong>parcelas seguintes deverão ser pagas por Boleto,</strong>{' '}
            até a data de vencimento estabelecida. O boleto será enviado por
            e-mail ou poderá ser obtido na página "Meus acordos" nessa
            plataforma.
          </p>
        </Message>
      )}

      {currentOptionHasAboutPix && isCarrefour && (
        <Message
          type="warning"
          customClass="negotiation"
          icon={<IconNegotiation iconColor="dark" />}
        >
          <p>
            Ao optar pelo <strong>pagamento via PIX,</strong> todas as parcelas
            do seu acordo devem ser liquidadas utilizando a mesma opção
            escolhida inicialmente.
          </p>
        </Message>
      )}
    </>
  );
};

export default WarningNegotiation;
