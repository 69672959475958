import Button from '@libs/atlas/components/Button';
import { CardOfferButtonProps } from './types';

const CardOfferButton = ({
  title,
  onClick,
  loading = false,
  ariaLabel = 'Ir para o site do parceiro',
  type = 'primary',
}: CardOfferButtonProps) => {
  return (
    <div className="card-offer-container__action-button">
      <Button
        onClick={onClick}
        expand="x"
        loading={loading}
        aria-label={ariaLabel}
        type={type}
      >
        {title}
      </Button>
    </div>
  );
};

export default CardOfferButton;
