import { IconMail } from '@libs/atlas/components/icons/default';
import Conditional from '@libs/atlas/components/misc/Conditional';
import Text from '@libs/atlas/components/Text';
import { useUserStore } from '@common/store/useUserStore';
import './styles.scss';
import { DebtData } from '@common/hooks/useDebts/types';

interface EmailInfoProps {
  debtData: DebtData;
  flowWithPix?: boolean;
}

const EmailInfo = ({ debtData, flowWithPix }: EmailInfoProps) => {
  const user = useUserStore((state) => state.user);

  return (
    <div className="email-delivery-info">
      <Text className="email-delivery-info__disclaimer" as="p" weight="regular">
        Você receberá o boleto{' '}
        {flowWithPix && !debtData.currentPartner.isCarrefour && (
          <span> e o código do Pix </span>
        )}{' '}
        no e-mail cadastrado na Acordo Certo.
      </Text>
      <Text className="email-delivery-info__disclaimer" as="p" weight="regular">
        E-mail cadastrado: <br />
        <strong>{user.email}</strong>
      </Text>
    </div>
  );
};

export default EmailInfo;
