import Text from '../../Text';
import Button from '../../Button';
import { AccordionItemProps } from '../types';
import {
  IconChevronDown,
  IconPlusCircle,
} from '../../icons/default';
import { IconMinusCircle } from '../../icons/default';

import '../style.scss';
import Conditional from '../../misc/Conditional';

const Item = ({
  title,
  isOpen,
  onToggle,
  children,
  hasChevron = false,
}: AccordionItemProps) => {
  return (
    <li className="accordion">
      <Button onClick={onToggle}>
        <Text className="accordion__title">{title}</Text>
        <div className={`accordion__icon ${isOpen ? 'rotate' : ''}`}>
          <Conditional
            condition={hasChevron}
            renderIf={<IconChevronDown />}
            renderElse={isOpen ? <IconMinusCircle /> : <IconPlusCircle />}
          />
        </div>
      </Button>
      {isOpen && (
        <div className={`accordion__description ${isOpen ? 'click' : 'close'}`}>
          {children}
        </div>
      )}
    </li>
  );
};

export default Item;
