import { SimulatedPaymentOption } from '@common/apis/simulated-debt/types';
import { AgreementInstallmentProps } from '@dues/apis/agreements/types';

const INSTALLMENT_WITHOUT_DISCOUNT_PROP = 'INSTALLMENT_VALUE_WITHOUT_DISCOUNT';

export function getAgreementOverdueInstallmentValue(
  currentInstallmentInfo: AgreementInstallmentProps
): number | undefined {
  const props = currentInstallmentInfo.properties || [];
  const usedProp = props.find(
    (prop) => prop.key === INSTALLMENT_WITHOUT_DISCOUNT_PROP
  );
  if (usedProp) return usedProp.decimalValue;
}

export function getPaymentOptiontOverdueInstallmentValue(
  paymentOption: SimulatedPaymentOption
) {
  const safeProps = paymentOption?.propriedades || [];
  const usedProp = safeProps.find(
    (prop) => prop.chave === INSTALLMENT_WITHOUT_DISCOUNT_PROP
  );
  if (usedProp) return usedProp.valorDecimal;
}
