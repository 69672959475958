import { Field, Form } from 'houseform';

import Button from '@libs/atlas/components/Button';
import InputField from '@libs/atlas/components/Form/InputField';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import Modal from '@common/components/Modal';

import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import { maskBirthdate } from '@libs/utils/masks/birthdate';

import './styles.scss';
import { useOfferModalDigio } from './hook';

type OfferModalDigioProps = {
  location: string;
  visible: boolean;
  onCloseModal: () => void;
};

export function OfferModalDigio({
  location,
  visible,
  onCloseModal,
}: OfferModalDigioProps) {
  const { isDesktop } = useDeviceSize();
  const { stepForm, handleProposal, openModal, validateBirthdateField } =
    useOfferModalDigio(visible, location);

  const title =
    stepForm === 'birthdate'
      ? 'Para enviar seu pedido, precisamos de uma informação'
      : 'Você está perto de conquistar seu cartão Digio!';

  return (
    <Modal show={openModal} onCloseClick={onCloseModal}>
      <div className="modal-digio">
        <div className="modal-digio__header">
          <Text as="h3" variant="heading-three" weight="semibold">
            {title}
          </Text>
        </div>
        <div className="modal-digio__content">
          <Conditional
            condition={stepForm === 'birthdate' || stepForm === 'next'}
            renderIf={
              <>
                <Text as="label" variant="body-large" weight="semibold">
                  Qual é a sua data de nascimento?
                </Text>
                <Form onSubmit={handleProposal}>
                  {({ submit }) => (
                    <>
                      <div className="modal-digio__content__input-container">
                        <Field
                          name="birthdate"
                          preserveValue={true}
                          onBlurValidate={validateBirthdateField}
                        >
                          {({ value, onBlur, setValue, errors }) => {
                            return (
                              <InputField
                                value={value}
                                onBlur={onBlur}
                                onChange={(e) =>
                                  setValue(maskBirthdate(e.target.value))
                                }
                                htmlType="text"
                                label="Data de nascimento"
                                errors={errors}
                                inputMode="numeric"
                              />
                            );
                          }}
                        </Field>
                        <Text
                          as="p"
                          variant="body-small"
                          weight="semibold"
                          color="light"
                        >
                          O parceiro precisa desse dado para trazer a melhor
                          oferta
                        </Text>
                      </div>
                      <Button
                        aria-label="Pedir cartão"
                        expand="x"
                        onClick={submit}
                        disabled={stepForm === 'birthdate'}
                      >
                        Continuar
                      </Button>
                    </>
                  )}
                </Form>
              </>
            }
          />

          <Conditional
            condition={stepForm === 'loading'}
            renderIf={
              <>
                <div className="modal-digio__content__loader-container">
                  <div className="loader-icon"/>
                  <Text as="p" variant="body-large" weight="regular">
                    Aguarde, enviando seu pedido para o parceiro...
                  </Text>
                </div>
                <Conditional
                  condition={isDesktop}
                  renderIf={
                    <Button expand="x" disabled>
                      Ok, entendi!
                    </Button>
                  }
                />
              </>
            }
          />

          <Conditional
            condition={stepForm === 'success'}
            renderIf={
              <>
                <div className="modal-digio__content__text-container">
                  <Text as="p" variant="body-large" weight="regular">
                    O parceiro vai analisar seu perfil e dando certo{' '}
                    <Text as="strong" variant="body-large" weight="semibold">
                      enviará no seu e-mail um token para finalizar o pedido do
                      cartão.
                    </Text>{' '}
                    Não se preocupe, é comum e torcemos que vai dar certo.
                  </Text>
                  <Text as="p" variant="body-large" weight="regular">
                    Para garantir, dá uma olhada no spam também!
                  </Text>
                </div>
                <Button expand="x" onClick={onCloseModal}>
                  Ok, entendi!
                </Button>
              </>
            }
          />
        </div>
      </div>
    </Modal>
  );
}
