type DebitCardContractProps = {
  desc: string | number;
  type?: 'contract' | 'source',
};

const DebitCardContract = ({ desc, type = "contract" }: DebitCardContractProps) => {
  const title = {
    contract: 'Contrato',
    source: 'Origem',
  };

  return (
    <div className="debit-card__contract">
      {title[type]}: <span className="debit-card__text-bold">{desc}</span>
    </div>
  );
};

export default DebitCardContract;
