import { SimulatedDebtRequest } from './types';

export const transformPayload = (
  { documento, partnerSystemIdentifier, referralId }: SimulatedDebtRequest,
) => {
  return {
    documento,
    ...(referralId && { referralId }),
    parameters: {
      partnerSystemIdentifier,
    },
  };
};
