import { TypesConfigOptional } from "react-spinetic/types";

export const offerCarouselConfig: TypesConfigOptional = {
  autoWidth: true,
  layout: "ctrls-in-line",
  draggable: false,

  responsive: [
    {
      breakpoint: 580,
      settings: {
        layout: "ctrls-in-line-2",
        maxDots: true
      }
    }
  ]
};
