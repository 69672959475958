import { Conditional } from '@consumidor-positivo/aurora';

import ClosedAgreementV1 from './ClosedAgreementV1';
import ClosedAgreementV2 from './ClosedAgreementV2';
import useClosedAgreementExperiment from '@common/abtest/experiments/useClosedAgreementExperiment';

const ClosedAgreement = () => {
  const { resultIsNewPaymentPage } = useClosedAgreementExperiment();
  return (
    <Conditional
      condition={resultIsNewPaymentPage}
      renderIf={<ClosedAgreementV2 />}
      renderElse={<ClosedAgreementV1 />}
    />
  );
};

export default ClosedAgreement;
