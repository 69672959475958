
// This file is generated automatically
// To edit see the file libs/atlas/scr/tasks/generateIcons.js
import Icon from "../index";

export default function IconPix(props: any) {
  return (
    <Icon
      iconMarkup={"<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14.9368 3.58326C13.3173 1.96378 10.6828 1.96378 9.06335 3.58326L3.58326 9.06335C1.96378 10.6828 1.96378 13.3173 3.58326 14.9368L9.06335 20.4169C10.6828 22.0364 13.3173 22.0364 14.9368 20.4169L20.4169 14.9368C22.0364 13.3173 22.0364 10.6828 20.4169 9.06335L14.9368 3.58326ZM10.4378 4.95769C11.2991 4.0964 12.7011 4.0964 13.5624 4.95769L15.3982 6.79348C14.7802 6.89899 14.2105 7.19209 13.7586 7.64407L12.0558 9.42677C12.0404 9.44148 12.0215 9.44908 12.0001 9.44908C11.9783 9.44908 11.9588 9.44112 11.9427 9.42501L10.1665 7.73017C9.71214 7.2758 9.13896 6.98213 8.51722 6.87826L10.4378 4.95769ZM6.84482 15.4495L4.95769 13.5624C4.0964 12.7011 4.0964 11.2991 4.95769 10.4378L6.61553 8.77995L8.00806 8.77995C8.30511 8.77995 8.58298 8.89521 8.79346 9.10611L10.5701 10.8011C10.9519 11.1823 11.4605 11.3926 12.0001 11.3926C12.5407 11.3926 13.0501 11.1815 13.4319 10.7993L15.1349 9.01673C15.3448 8.80783 15.6221 8.69333 15.9183 8.69333H17.298L19.0425 10.4378C19.9038 11.2991 19.9038 12.7011 19.0425 13.5624L17.242 15.3629H15.8221C15.4883 15.3629 15.1192 15.2559 14.94 15.0495L13.4377 13.3491L13.4323 13.3437C13.0502 12.9612 12.5407 12.75 12.0001 12.75C11.4618 12.75 10.9543 12.9594 10.5731 13.3385L8.59806 15.1148L8.58872 15.1242C8.37859 15.3343 8.10047 15.4495 7.80332 15.4495H6.84482ZM15.3252 17.2796L13.5624 19.0425C12.7011 19.9038 11.2991 19.9038 10.4378 19.0425L8.66567 17.2704C9.14827 17.1291 9.59084 16.8689 9.95684 16.5049L11.9369 14.7798L11.9431 14.7736C11.9586 14.7581 11.9781 14.75 12.0001 14.75C12.0219 14.75 12.0413 14.758 12.0574 14.7742L13.8266 16.4139C14.0961 16.6837 14.3577 16.9096 14.6591 17.0661C14.864 17.1725 15.0815 17.2438 15.3252 17.2796Z' fill='currentColor'/></svg>"}
      iconName="IconPix"
      iconSize={props.size}
      iconColor={props.color}
      {...props}
    />
  );
}

