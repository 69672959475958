import { TypesConfigOptional } from 'react-spinetic/types';

export const carouselConfig: TypesConfigOptional = {
  showItems: 1,
  layout: "ctrls-in-line-2",
  arrowsStyle: {
    container: {},
    btnPrev: {
      marginLeft: "24px",
      border: "1px solid white",
    },
    btnNext: {
      marginRight: "24px",
      border: "1px solid white",
    },
  },
  dotsStyle: {
    container: {
      gap: 8,
    },
    dot: {
      height: 8,
      width: 8,
      background: '#c5d8ff',
    },
    dotActive: {
      background: 'white',
      width: 8,
      opacity: 1
    },
  },
};
