import { DebtData } from '@common/hooks/useDebts/types';
import { IconMoney, Text } from '@consumidor-positivo/aurora';
import Report from '@libs/atlas/components/Report';
import classNames from 'classnames';

interface InstallmentDataProps {
  debtData: DebtData;
  hasDebitAccount: boolean;
  firstInstallmentDate: string;
}

const InstallmentDataCard = ({
  debtData,
  hasDebitAccount,
  firstInstallmentDate,
}: InstallmentDataProps) => {
  return (
    <div className="closed-agreement-page-v2__installment-data">
      <div
        className={classNames('closed-agreement-page-v2__installment-title', {
          'closed-agreement-page-v2__installment-title--debit-account':
            hasDebitAccount,
        })}
      >
        <Text variant="heading-micro" weight="bold">
          Dados da parcela
        </Text>
        <Report.Root border="less" type="warning">
          <Report.Icon>
            <IconMoney />
          </Report.Icon>
          <Report.Text>Acordo em andamento</Report.Text>
        </Report.Root>
      </div>
      <div>
        <div className="closed-agreement-page-v2__installment-card-line">
          <Text color="secondary">
            Parcela 1 de {debtData.paymentOpt.qtyInstallment}:
          </Text>
          <Text color="secondary" weight="bold">
            {debtData.paymentOpt.display.entryValue}
          </Text>
        </div>
        <div className="closed-agreement-page-v2__divider" />

        <div className="closed-agreement-page-v2__installment-card-line">
          <Text color="secondary">Vencimento:</Text>
          <Text color="secondary" weight="bold">
            {firstInstallmentDate}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default InstallmentDataCard;
