import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { CardOfferRootProps } from './types';

const CardOfferRoot = ({
  children,
  place,
  preApproved = false,
}: CardOfferRootProps) => {
  return (
    <div className={`card-offer ${place}`}>
      <Conditional
        condition={preApproved}
        renderIf={
          <div className="pre-approved">
            <Text
              as="p"
              weight="semibold"
              color="white"
              className="pre-approved--title"
            >
              Oferta pré-aprovada
            </Text>
          </div>
        }
      />
      <div className="card-offer-container">{children}</div>
    </div>
  );
};

export default CardOfferRoot;
