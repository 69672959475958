import emojiFire from '@libs/atlas/assets/icons/fire-emoji.svg';
import classNames from 'classnames';

type DebitCardPriceDiscountProps = {
  title?: string;
  value: string | number;
  customClass?: string;
};

const DebitCardPriceDiscount = ({ title, value, customClass }: DebitCardPriceDiscountProps) => {
  return (
    <div className="debit-card__discount">
      <span className="debit-card__discount__title">
        {title}
      </span>
      <div 
        className={classNames('debit-card__discount__info', {
          [`debit-card__discount__info--${customClass}`]: customClass,
        })}
      >
        <img src={emojiFire} className="debit-card__discount__icon" alt="emoji de fogo" />
        <span className="debit-card__discount__value">
          {value}
        </span>
      </div>
    </div>
  );
};

export default DebitCardPriceDiscount;
