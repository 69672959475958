import { useRef } from 'react';

import Conditional from '@libs/atlas/components/misc/Conditional';
import DebitCard from '@libs/atlas/components/DebitCard';
import DebtsModal from '@common/components/DebtsModal';

import './styles.scss';
import { SimulatedDebt } from '@common/apis/simulated-debt/types';
import { DebtData } from '@common/hooks/useDebts/types';

interface ConfirmAgreementHeader {
  debt: SimulatedDebt;
  debtData: DebtData;
}

const ConfirmAgreementHeader = ({ debt, debtData }: ConfirmAgreementHeader) => {
  const confirmAgreementNegativeRef = useRef<any>(null);

  return (
    <>
      <section className="confirm-agreement-header">
        <img
          className="confirm-agreement-header__logo"
          src={debtData.display.partnerImage}
          alt={`Logotipo da empresa ${debtData.identifier}`}
        />

        <div className="confirm-agreement-header__desc">
          <DebitCard.Text
            value={`<p><strong>${debtData.display.name}</strong></p>`}
          />
          <Conditional
            condition={!!debtData.display.product}
            renderIf={
              <DebitCard.Description>
                {debtData.display.product}
              </DebitCard.Description>
            }
          />
          <Conditional
            condition={!!debtData.visibleContract}
            renderIf={<DebitCard.Contract desc={debtData.display.contract} />}
          />
          <Conditional
            condition={!!debtData.visibleSource}
            renderIf={
              <DebitCard.Contract
                type="source"
                desc={debtData.display.source}
              />
            }
          />{' '}
          {/* TODO TEST A/B >> invert name by source*/}
          <Conditional
            condition={debtData.debtIsNegative}
            renderIf={
              <DebitCard.NegativedFlag
                onClick={() =>
                  confirmAgreementNegativeRef.current.open({
                    debt,
                    buttonText: 'OK',
                    closeModalOnClickCta: true,
                  })
                }
              />
            }
          />
        </div>
        <DebtsModal.Negative ref={confirmAgreementNegativeRef} />
      </section>
    </>
  );
};

export default ConfirmAgreementHeader;
