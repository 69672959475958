import Text from '@libs/atlas/components/Text';
import Report from '@libs/atlas/components/Report';
import Conditional from '@libs/atlas/components/misc/Conditional';
import DashedDivider from '@libs/atlas/components/DashedDivider';
import Button from '@libs/atlas/components/Button';
import Accordion from '@libs/atlas/components/Accordion';

import './styles.scss';

const PollingLoader = ({
  pix = false,
  isMyAgrementsPage = false,
  isOpen = true,
}) => {
  const paymentType = pix ? 'dados do Pix' : 'boleto';

  return (
    <div className="polling-loader">
      <div className="polling-loader__report">
        <Report.Root border="less" type="info">
          <Report.Text>Gerando {paymentType}</Report.Text>
        </Report.Root>
        <DashedDivider />
      </div>

      <Conditional
        condition={isMyAgrementsPage}
        renderIf={
          <Accordion
            items={[
              {
                isOpen: isOpen,
                title: 'Por que o Boleto/Pix ainda está sendo gerado?',
                content: (
                  <>
                    <Text weight="regular" color="medium">
                      Normalmente após o fechamento do acordo, o primeiro boleto
                      ou código Pix{' '}
                      <strong>
                        pode ficar disponível entre 1 minuto ou até 24h.
                      </strong>
                    </Text>

                    <Text weight="regular" color="medium">
                      Já a partir da segunda parcela os boletos ou código pix,
                      ficam disponivel entre <strong>2 a 5 dias </strong>antes
                      do vencimento da parcela.
                    </Text>

                    <Text weight="regular" color="medium">
                      Caso você já esteja no prazo de vencimento e ainda não
                      conseguiu ter acesso as informações para o pagamento do
                      seu acordo, por favor entre em contato com o nosso time de
                      atendimento através do botão abaixo.
                    </Text>

                    <Button
                      type="secondary"
                      borderWidth="thin"
                      expand="x"
                      as="a"
                      target="__blank"
                      href="https://www.acordocerto.com.br/formulario"
                    >
                      Falar com atendimento
                    </Button>
                  </>
                ),
              },
            ]}
          />
        }
        renderElse={
          <div className="polling-loader__closed-agreement">
            <Text weight="semibold">
              Por que o Boleto/Pix ainda está sendo gerado?
            </Text>
            <Text weight="regular" color="medium">
              Normalmente após o fechamento do acordo, o primeiro boleto ou
              código Pix{' '}
              <strong>pode ficar disponível entre 1 minuto ou até 24h.</strong>
            </Text>

            <Text weight="regular" color="medium">
              Assim que o boleto ou código Pix ficar disponível{' '}
              <strong>você também será avisado via e-mail.</strong>
            </Text>
          </div>
        }
      />
    </div>
  );
};

export default PollingLoader;
