import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Text from '../Text';
import { IconX } from '../icons/default';
import Conditional from '../misc/Conditional';
import './styles.scss';

type DrawerProps = {
  buttonText?: string;
  children: ReactNode;
  className?: string;
  isActive: boolean;
  isLoading?: boolean;
  onClickButton?: (e: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  subTitle?: string;
  title?: string;
  hasSeparator?: boolean;
  icon?: ReactNode;
  customIconActions?: ReactNode;
  closeOnClickOutside?: boolean;
};

const Drawer = ({
  buttonText,
  children,
  className,
  isActive,
  isLoading,
  onClickButton,
  onClose,
  subTitle,
  title,
  hasSeparator = true,
  icon,
  customIconActions,
  closeOnClickOutside = true
}: DrawerProps) => {
  const drawerContentRef = useRef<HTMLDivElement>(null);

  const navbarClasses = classNames('drawer', {
    'is-active': isActive,
    [className as string]: className && typeof className === 'string',
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        closeOnClickOutside &&
        drawerContentRef.current &&
        !drawerContentRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isActive]);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', isActive);
  }, [isActive])

  return (
    <div className={navbarClasses}>
      <div className="drawer__container" ref={drawerContentRef}>
        <div className="drawer__header">
          <div
            className={classNames('drawer__actions', {
              'drawer__actions-custom-icon': !!customIconActions,
            })}
          >
            {customIconActions}

            <button
              onClick={onClose}
              className="drawer__actions-close"
              aria-label="Fechar"
            >
              <IconX />
            </button>
          </div>

          <Conditional
            condition={!!icon}
            renderIf={
              <div className="drawer__icon">
                {icon}
              </div>
            }
          />

          <Text as="h3" className="drawer__title">
            {title}
          </Text>

          <Conditional
            condition={!!subTitle}
            renderIf={
              <p
                className="drawer__subtitle"
                dangerouslySetInnerHTML={
                  subTitle ? { __html: subTitle } : undefined
                }
              />
            }
          />

          <Conditional
            condition={hasSeparator}
            renderIf={
              <span className="drawer__separator" />
            }
          />
        </div>

        <div className="drawer__body">{children}</div>

        <Conditional
          condition={!!buttonText}
          renderIf={
            <div className="drawer__footer">
              <Button expand="x" onClick={onClickButton} loading={isLoading}>
                {buttonText}
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Drawer;
