import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import HeaderMainTitle from './HeaderMainTitle';
import { offerCategory } from '@common/constants/offerCategory';
import { CardOfferHeaderProps } from '../types';

const generateClassName = (logoSize: string, category?: string): string => {
  return category ? `${logoSize} ${logoSize}__${category}` : logoSize;
};

const CardOfferHeader = ({
  logo,
  name = '',
  subtitle = '',
  description = '',
  limit,
  logoSize,
  category,
  alt,
  isCardCarousel = false,
  forceShowTitle = false,
  isVisibleHeaderTabs = false,
  slug,
}: CardOfferHeaderProps) => {
  const title = category === offerCategory.LOANS ? description : name;

  const fixedSize = (
    {
      small: 88,
      large: 114,
    } as any
  )[logoSize || ''];

  const imgClassNames = generateClassName(logoSize || '', category);

  return (
    <div className="card-offer-container__header">
      <div className="card-offer-container__header--holder">
        <img
          src={logo}
          alt={alt}
          className={`card-offer-container__logo-img card-offer-container__logo-img--${category} ${imgClassNames}`}
          width={fixedSize}
          height={96}
        />
        <Conditional
          condition={forceShowTitle}
          renderIf={
            <Text
              as="span"
              color="principal"
              className="card-offer-container__header--title-loan"
            >
              {name}
            </Text>
          }
        />
      </div>
      <Conditional
        condition={isCardCarousel || isVisibleHeaderTabs}
        renderIf={
          <div>
            <HeaderMainTitle
              title={title}
              subtitle={subtitle}
              limit={limit}
              slug={slug}
            />
          </div>
        }
      />
    </div>
  );
};

export default CardOfferHeader;
