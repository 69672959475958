import Accordion from '@libs/atlas/components/Accordion';

import { useConfirmAgreementAccordion } from './hooks';
import {
  SimulatedDebt,
  SimulatedPaymentOption,
} from '@common/apis/simulated-debt/types';

import { DebtData } from '@common/hooks/useDebts/types';
import { CurrentExtraInfo } from '../../helpers/types';

import Text from '@libs/atlas/components/Text';

import './styles.scss';

interface ConfirmAgreemntAccordion {
  debt: SimulatedDebt;
  paymentOption: SimulatedPaymentOption;
  debtData: DebtData;
  path?: string;
  currentExtraInfo?: CurrentExtraInfo;
  titleSection?: string;
  visibleLine?: boolean;
}

const ConfirmAgreementAccordion = ({
  debt,
  paymentOption,
  debtData,
  path = 'confirmacao',
  currentExtraInfo,
  titleSection,
  visibleLine = false,
}: ConfirmAgreemntAccordion) => {
  const { accordionItems } = useConfirmAgreementAccordion({
    debt,
    paymentOption,
    debtData,
    path,
    currentExtraInfo,
  });

  return (
    accordionItems?.length > 0 && (
      <>
        <section className="confirm-agreement-accordion">
        {visibleLine && (<div className="confirm-agreement-accordion__line"></div>)}
          {!!titleSection && (
            <Text as="h3" className="more-debt-details__title">
              {titleSection}
            </Text>
          )}
          <Accordion items={accordionItems} />
        </section>
      </>
    )
  );
};

export default ConfirmAgreementAccordion;
