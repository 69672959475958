import Conditional from '@libs/atlas/components/misc/Conditional';
import giftEmoji from '../../assets/icons/emoji-gift.svg';
import fireEmoji from '../../assets/icons/emoji-fire.svg';
import classNames from 'classnames';

type TagProps = {
  tag: string;
  hasGiftEmoji?: boolean;
  isDashboard?: boolean;
  preApproved?: boolean;
  limit?: number;
  isVisibleHeaderTabs?: boolean;
  isCompressedLayout?: boolean;
};

const CardOfferTag = ({
  tag = '',
  hasGiftEmoji = false,
  isDashboard = false,
  preApproved = false,
  limit,
  isVisibleHeaderTabs = false,
}: TagProps) => {
  const preApprovedMessage = (preApproved && !isVisibleHeaderTabs && !limit) ? 'Oferta pré-aprovada' : tag;
  const limitMessage = (limit && !isVisibleHeaderTabs) ? 'Chance alta de aprovação' : tag
  const message = limitMessage || preApprovedMessage || tag;

  if (!message) return null;

  return (
    <div
      className={classNames('card-offer-container__tag', {
        dashboard: isDashboard,
        'card-offer-container__tag--preApproved': (preApproved && !isVisibleHeaderTabs) || limit,
        'card-offer-container__tag--default': !preApproved && !limit || isVisibleHeaderTabs,
      })}
    >
      <Conditional
        condition={hasGiftEmoji}
        renderIf={
          <img
            className="card-offer-container__tag--emoji"
            width="16"
            height="16"
            src={((preApproved || limit) && !isVisibleHeaderTabs) ? fireEmoji : giftEmoji}
            alt={((preApproved || limit) && !isVisibleHeaderTabs) ? "emoji de fogo" : "emoji de presente"}
          />
        }
      />
      {message}
    </div>
  );
};

export default CardOfferTag;
