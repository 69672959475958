const partnersLogos = {
  placeholder: "/114280/40x40/9665ef55de/placeholder.svg",
  ac: "/114280/41x40/e3835fe636/acordo-certo.svg",
  acordocerto: "/114280/41x40/e3835fe636/acordo-certo.svg",
  'ac-new': "/114280/40x40/9665ef55de/placeholder.svg",
  act: "/114280/41x40/7b5366eaf7/anhanguera.svg",
  ampli: "/114280/41x40/6050baa3c2/ampli.svg",
  'anhanguera-300': "/114280/41x40/7b5366eaf7/anhanguera.svg",
  'anhanguera-ead': "/114280/41x40/7b5366eaf7/anhanguera.svg",
  anhangueraead: "/114280/41x40/7b5366eaf7/anhanguera.svg",
  anhanguera: "/114280/41x40/7b5366eaf7/anhanguera.svg",
  'anhangueraead-300': "/114280/41x40/7b5366eaf7/anhanguera.svg",
  asp: "/114280/41x40/f53e1c243e/asp.svg",
  ativossa: "/114280/41x40/573508d64d/ativos-sa.svg",
  'ativossa-nsd': "/114280/41x40/573508d64d/ativos-sa.svg",
  'banco-do-brasil': "/114280/41x40/b86f8ba556/banco-do-brasil.svg",
  bancodobrasil: "/114280/41x40/b86f8ba556/banco-do-brasil.svg",
  'banco-next': "/114280/41x40/cf9d1fdf09/next.svg",
  'banco-pan': "/114280/41x40/0b1464c858/banco-pan.svg",
  biva: "/114280/41x40/200b7e7052/biva.svg",
  'bradescard-fmc': "/114280/41x40/416300b193/bradescard.svg",
  'bradescard-losango': "/114280/41x40/9d9c21e757/losango.svg",
  bradescard: "/114280/41x40/416300b193/bradescard.svg",
  'bradescard-p1': "/114280/41x40/416300b193/bradescard.svg",
  bradesco: "/114280/41x40/f7cf4c97ef/bradesco.svg",
  'bradesco-eavm': "/114280/41x40/f7cf4c97ef/bradesco.svg",
  'brk-ambiental': "/114280/41x40/5ea7b9aa5e/brk.svg",
  brk: "/114280/41x40/5ea7b9aa5e/brk.svg",
  btg: "/114280/41x40/f0e1cf017b/btg.svg",
  arc4u: "/114280/41x40/2f380c03b5/arc4u.svg",
  'bv-financeira': "/114280/41x40/fcc21a8eef/bv.svg",
  bv: "/114280/41x40/fcc21a8eef/bv.svg",
  c6bank: "/114280/41x40/af7d196932/c6.svg",
  'carrefour-atacadao': "/114280/41x40/341eac4bae/atacadao.svg",
  carrefour: "/114280/41x40/9a34523416/carrefour.svg",
  'casas-bahia': "/114280/41x40/f0aa3e4719/casas-bahia.svg",
  cencosud: "/114280/41x40/79c2a8dde9/cencosud.svg",
  cielo: "/114280/41x40/2d58582683/cielo.svg",
  'claro-fatura': "/114280/41x40/7307bdf4b7/claro.svg",
  'claro-residencial': "/114280/41x40/80b6be09da/minha-claro-residencial.svg",
  clarogevenue: "/114280/41x40/7307bdf4b7/claro.svg",
  claro: "/114280/41x40/7307bdf4b7/claro.svg",
  colombo: "/114280/41x40/8a86d9cb7f/colombo.svg",
  crediativos: "/114280/41x40/a3ac8bcbf6/crediativos.svg",
  credigy: "/114280/41x40/a3ac8bcbf6/crediativos.svg",
  credsystem: "/114280/41x40/26860bcaae/credisystem.svg",
  credz: "/114280/41x40/503ec8fe8b/credz.svg",
  dacasa: "/114280/41x40/ff4276c294/dacasa.svg",
  'dm-logo': "/114280/41x40/e9b314e4f5/dm-logo.svg",
  edp: "/114280/41x40/a93257eb1c/edp.svg",
  estacio: "/114280/41x40/1eac7e6bc8/estacio.svg",
  faci: "/114280/41x40/a5fd463de1/faci-wyden.svg",
  facimp: "/114280/41x40/d0ec1b90df/facimp-wyden.svg",
  fmf: "/114280/41x40/5823066719/martha-falcao-wyden.svg",
  'fundo-atlantico': "/114280/41x40/40e4306f0b/fundo-atlantico.svg",
  havan: "/114280/41x40/11663f904f/havan.svg",
  hoepers: "/114280/41x40/8a22b27df6/hoepers.svg",
  ibmec: "/114280/41x40/39802b18cd/ibmec.svg",
  ipanema: "/114280/41x40/5901896771/ipanema.svg",
  itapeva: "/114280/41x40/3379f818af/itapeva.svg",
  itapevaadhil: "/114280/41x40/3379f818af/itapeva.svg",
  itau: "/114280/41x40/24cb91df83/itau.svg",
  'itau-pf': "/114280/41x40/24cb91df83/itau.svg",
  'itau-pj': "/114280/41x40/24cb91df83/itau.svg",
  'itau-rdhi': "/114280/41x40/24cb91df83/itau.svg",
  kroton: "/114280/41x40/93b1111728/kroton.svg",
  fama: "/114280/38x40/0fa08ec2a7/fama.svg", // TODO add new model
  liftcred: "/114280/41x40/f5faf9852a/liftcred.svg",
  lojasrenner: "/114280/41x40/ec7c84d930/renner.svg",
  luizacred: "/114280/41x40/244eac525d/luizacred.svg",
  mercadolivre: "/114280/41x40/ca97cec082/mercado-livre.svg",
  mercadopago: "/114280/41x40/af475de80e/mercado-pago.svg",
  'mgw-ativos': "/114280/41x40/251bce1744/mgw.svg",
  'net-interno': "/114280/41x40/80b6be09da/minha-claro-residencial.svg",
  'net-seo': "/114280/41x40/80b6be09da/minha-claro-residencial.svg",
  net: "/114280/41x40/80b6be09da/minha-claro-residencial.svg",
  nubank: "/114280/41x40/8d256242a2/nubank.svg",
  oi: "/114280/46x40/619de12800/acordo-certo-oi.svg", // TODO add new model
  omni: "/114280/41x40/872b3324b2/omni.svg",
  original: "/114280/41x40/033d4a89af/banco-original.svg",
  'pag-bank': "/114280/41x40/506b2f0296/pagbank.svg",
  pagbank: "/114280/41x40/506b2f0296/pagbank.svg",
  pan: "/114280/41x40/0b1464c858/banco-pan.svg",
  'pitagoras-300': "/114280/41x40/161d53913f/pitagoras.svg",
  pitagoras: "/114280/41x40/161d53913f/pitagoras.svg",
  platos: "/114280/41x40/57feeeba64/platos.svg",
  ponto: "/114280/41x40/4662532e50/ponto-frio.svg",
  'porto-seguro': "/114280/41x40/c3426de201/porto-seguro.svg",
  recovery: "/114280/41x40/61f491c12a/recovery.svg",
  recoveryitau: "/114280/41x40/24cb91df83/itau.svg",
  'riachuelo-sipf': "/114280/41x40/d01fd08e58/riachuelo.svg",
  'riachuelo-v2': "/114280/41x40/d01fd08e58/riachuelo.svg",
  riachuelo: "/114280/41x40/d01fd08e58/riachuelo.svg",
  'saneaqua-mairinque': "/114280/41x40/720a561cfb/saneaqua.svg",
  saneaqua: "/114280/41x40/720a561cfb/saneaqua.svg",
  santander: "/114280/41x40/19010e3970/santander.svg",
  sky: "/114280/41x40/be34121507/sky.svg",
  tribanco: "/114280/41x40/1afc480e07/tribanco.svg",
  trigg: "/114280/41x40/7f710457dd/trigg.svg",
  'unic-300': "/114280/41x40/351cecaadc/unic.svg",
  unic: "/114280/41x40/351cecaadc/unic.svg",
  'uniderp-300': "/114280/41x40/f279854a44/uniderp.svg",
  uniderp: "/114280/41x40/f279854a44/uniderp.svg",
  unifacid: "/114280/41x40/5849ccac57/unifacid-wyden.svg",
  unifanor: "/114280/41x40/faa79349d4/unifanor-wyden.svg",
  unifavip: "/114280/41x40/f7ef2f3a18/unifavip-wyden.svg",
  unifbv: "/114280/41x40/44d6b4f761/unifbv-wyden.svg",
  'unime-300': "/114280/41x40/12b2c7b887/unime.svg",
  unime: "/114280/41x40/12b2c7b887/unime.svg",
  unimetrocamp: "/114280/41x40/850fbd465f/uni-metro-camp-wyden.svg",
  uniruy: "/114280/41x40/3f4f3ebb20/uniruy-wyden.svg",
  unitoledo: "/114280/41x40/7a98e8fe03/unitoledo-wyden.svg",
  'unopar-300': "/114280/41x40/40e78c1d99/unopar.svg",
  unopar: "/114280/41x40/40e78c1d99/unopar.svg",
  uzecartoes: "/114280/41x40/3f6e7c4442/uze.svg",
  'via-varejo': "/114280/41x40/d3120d7137/via.svg",
  vivo: "/114280/41x40/25d0ce91d1/vivo.svg",
  vivocyber: "/114280/41x40/25d0ce91d1/vivo.svg",
  vivonext: "/114280/41x40/25d0ce91d1/vivo.svg",
  sophus: "/114280/41x40/61a8117f7b/acertando-suas-contas.svg",
  tim: "/114280/41x40/ba0dfd5d81/tim.svg",
  pernambucanas: "/114280/132x40/ff98571a13/acordo-certo-pernambucanas.svg", // TODO Add new model
  'pernambucanas-ws': "/114280/132x40/ff98571a13/acordo-certo-pernambucanas.svg", // TODO add new model
  'pernambucanas-offline': "/114280/132x40/ff98571a13/acordo-certo-pernambucanas.svg", // TODO add new model
  wyden: "/114280/41x40/89ce7bfcb8/wyden.svg",
  neon: "/114280/41x40/a0996f7466/neon.svg",
};

export const getPartnerImage = (partnerIdentifier = '', altMap?: any) => {

  const identifier = partnerIdentifier as keyof typeof partnersLogos;
  const logo = partnersLogos?.[identifier] ?? partnersLogos.placeholder
  return `https://assets.acordocerto.com.br/f${logo}`
};
