import Message from '@libs/atlas/components/Message';
import Conditional from '@libs/atlas/components/misc/Conditional';
import Text from "@libs/atlas/components/Text";

import { Warnings } from '../../helpers/types';

import './styles.scss';

const Information = ({ importantInfoText, importantInfoTextWithTitle }: Warnings) => {
  return (
    <>
      <Conditional
        condition={!!importantInfoText}
        renderIf={
          <Message
            type="warning"
            customClass="information"
          >
            <Text
              as="span"
              className="terms-conditions-without-ac__description"
              dangerouslySetInnerHTML={importantInfoText}
            />
          </Message>
        }
      />
      <Conditional
        condition={!!importantInfoTextWithTitle}
        renderIf={
          <Message
            type="warning"
            customClass="information"
          >
            <h3>Importante</h3>
            <Text
              as="span"
              className="terms-conditions-without-ac__description"
              dangerouslySetInnerHTML={importantInfoTextWithTitle}
            />
          </Message>
        }
      />
    </>
  );
};

export default Information;
