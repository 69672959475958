import classNames from 'classnames';
import useBillet from '@common/hooks/useBillet';
import Button from '@libs/atlas/components/Button';
import Text from '@libs/atlas/components/Text';
import {
  IconBarCode2,
  IconCheck,
  IconCopy,
} from '@libs/atlas/components/icons/default';
import Message from '@libs/atlas/components/Message';
import { BilletProps } from '../../types';

import '../../styles.scss';

const Billet = ({ closedAgreement, billet }: BilletProps | any) => {
  const {
    handleCopyBilletCode,
    handleBilletDownload,
    buttonText,
    isCodeCopied,
    isBilletLoading,
    isBilletSuccess,
  } = useBillet({
    installment: closedAgreement?.agreement?.acordoParcelas[0],
    msUrl: closedAgreement?.debt?.partner.msUrl,
    closedAgreement
  });

  return (
    <div className="payment-section__billet">
      <div className="payment-section__subheading">
        <IconBarCode2 />
        <Text>Código do boleto</Text>
      </div>

      <Text as="p" weight="regular" className="payment-section__code">
        {billet?.linhaDigitavel}
      </Text>

      <div className="payment-section__buttons">
        <Button
          className={classNames('payment-section__copy-code', {
            copied: isCodeCopied,
          })}
          expand="x"
          onClick={() =>
            handleCopyBilletCode(billet?.linhaDigitavel)
          }
          disabled={isBilletLoading || isBilletSuccess}
        >
          {isCodeCopied ? <IconCheck /> : <IconCopy iconSize="sm" />}

          {buttonText}
        </Button>
        <Button
          type="secondary"
          expand="x"
          onClick={handleBilletDownload}
          disabled={isBilletLoading || isBilletSuccess}
        >
          Baixar boleto
        </Button>
      </div>
    </div>
  );
};

export default Billet;
