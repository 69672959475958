import './styles.scss';

const PaymentSectionSkeleton = () => {
  return (
    <>
      <div className="payment-section-skeleton report" />
      <div className="payment-section-skeleton subheading" />
      <div className="payment-section-skeleton code" />
      <div className="payment-section-skeleton button" />
      <div className="payment-section-skeleton button" />
    </>
  );
};

export default PaymentSectionSkeleton;
