import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import { IconSaveMoney } from '@libs/atlas/components/icons/default';
import { DebitAccountProps } from '../../types';

import './styles.scss';

const DebitAccount = ({ debtData, closedAgreement }: DebitAccountProps) => {
  return (
    <div className="debit-acc">
      <div className="debit-acc__title-container">
        <IconSaveMoney />
        <Text as="h3">Débito em conta</Text>
      </div>

      <Text as="p" className="debit-acc__description" weight="regular">
        O débito em conta previsto nesta negociação ocorrerá na seguinte conta
        bancária selecionada:
      </Text>

      <Conditional
        condition={!!closedAgreement?.bankData?.selectedBankAccount}
        renderIf={
          <table className="debit-acc__bank-data-table">
            <tbody>
              <tr className="debit-acc__bank-data-table_titles">
                <td>Conta</td>
                <td>Agência</td>
              </tr>
              <tr className="debit-acc__bank-data-table_content">
                <td>
                  {closedAgreement?.bankData?.selectedBankAccount?.account}
                </td>
                <td>
                  {closedAgreement?.bankData?.selectedBankAccount?.agency}
                </td>
              </tr>
            </tbody>
          </table>
        }
      />

      <div className="division-of-selectors" />

      <div className="debit-acc__infos-box">
        <Text as="p" weight="regular">
          Orientamos a não movimentar sua conta corrente desde a confirmação de
          sua renegociação até o pagamento da parcela,{' '}
          <strong>
            evitando a alteração de seu saldo e, consequentemente, o
            cancelamento do acordo, ok?
          </strong>
        </Text>
      </div>
    </div>
  );
};

export default DebitAccount;
