import { useEffect, useState } from 'react';
import { OfferProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserStore } from '@common/store/useUserStore';

import { useStoryblokDatasource } from '@libs/storyblok';

import { offerCategory } from '@common/constants/offerCategory';
import { pageNames } from '@common/routes/pageNames';
import useApiFirebolt from '@common/apis/firebolt/useApiFirebolt';
import useTrackOffer from '../useTrackOffer';
import { getSessionID } from 'redline-client-sdk';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { isIOS } from '@libs/utils/helpers/mobileOS';
import { isSafari } from '@libs/utils/helpers/isSafari';
import { getSiteIdParam } from '@libs/utils/helpers/getSiteIdParam';
import { getDeliveryIdParam } from '@libs/utils/helpers/getDeliveryIdParam';
import { getFbAvailableCookies } from '@libs/utils/helpers/getFacebookPixel';
import { getGAAvailableCookies } from '@libs/utils/helpers/getGoogleAnalyticsCookies';
import {
  addAutofillParam,
  FireboltUserInfo,
} from '@libs/utils/helpers/addAutofillParam';

import { SHDecision } from '@libs/sortinghat';
import { useSortingHatLib } from '../useSortingHatLib';

type GetOffersType = {
  callSHDecide?: boolean;
};

const useGetOffers = ({ callSHDecide }: GetOffersType = {}) => {
  const navigate = useNavigate();
  const { trackProductViewed, trackProposalStarted, trackElementClicked } =
    useTrackOffer();

  const {
    decisionsList,
    decideDone: decideFinish,
    shLoading,
    shDecision,
  } = useSortingHatLib();

  useEffect(() => {
    if (callSHDecide) {
      shDecision();
    }
  }, []);

  const { sendSoftProposal } = useApiFirebolt();
  const { trackProductClicked, trackRedirectRequested } = useTrackOffer();
  const { user, myDebt } = useUserStore((state) => state);
  const [userNegative, setUserNegative] = useState<boolean>(false);
  const [featuredOffers, setFeaturedOffers] = useState<OfferProps[]>([]);
  const [preApprovedOffers, setPreApprovedOffers] = useState<OfferProps[]>([]);
  const [filteredOffers, setFilteredOffers] = useState<OfferProps[]>([]);
  const [offers, setOffers] = useState<OfferProps[]>([]);
  const [openDigioModal, setOpenDigioModal] = useState(false);

  const { getDatasource } = useStoryblokDatasource();

  const location = useLocation();

  const initialCategory = location?.state?.key
    ? location.state.key
    : offerCategory.ALL;

  const noOffer = !shLoading && decideFinish && decisionsList.length <= 0;

  const createOfferObjects = (decisions: SHDecision[] = []) => {
    return decisions
      ?.map((decision) => {
        const storyblokContent = decision?.metadata?.content;
        if (!storyblokContent) {
          console.error(`Missing storyblok data for decision ${decision.slug}`);
          return {} as OfferProps;
        } else {
          const offers: OfferProps = {
            content: storyblokContent,
            shDecision: decision,
          };
          return offers;
        }
      })
      .filter((offers) => !!offers.content);
  };

  const setStateOffers = (offers: OfferProps[]) => {
    const filteredFeaturedOffers = filterByType(offers, 'featured');
    const filteredPreAapprovedOffers = filterByPath(filteredFeaturedOffers);
    const filteredFeaturedByPath = filterByPath(filteredFeaturedOffers);

    setOffers(offers);
    setFeaturedOffers(filteredFeaturedByPath);
    setPreApprovedOffers(filteredPreAapprovedOffers);
  };

  const filterByPath = (offers: OfferProps[]) => {
    const filteredResult = [
      'ac-destaque-dashboard',
      'ac-destaque-ofertas',
      'ac-monitoramento-financeiro-destaque-ofertas',
      'ac-ofertas-pre-aprovadas-lista-ofertas',
    ].reduce((acc: any, curr: string) => {
      const filteredSlug = offers.filter(({ shDecision }) => {
        const safeFeatured = Array.isArray(shDecision['featured'])
          ? shDecision['featured']
          : [];
        return safeFeatured?.includes(curr);
      });

      return acc
        ? {
            ...acc,
            [curr]: filteredSlug,
          }
        : acc;
    }, {});

    const slugsByPath: Record<string, string> = {
      '/minhas-dividas': 'ac-destaque-dashboard',
      [`/${myDebt?.partner?.identifier}/acordo-fechado`]: 'ac-destaque-dashboard',
      '/ofertas': 'ac-destaque-ofertas',
      '/ofertas/ofertas-pre-aprovadas':
        'ac-ofertas-pre-aprovadas-lista-ofertas',
      '/monitoramento/financeiro':
        'ac-monitoramento-financeiro-destaque-ofertas',
    };

    return filteredResult[slugsByPath[location.pathname]];
  };

  const filterByType = (offers: OfferProps[], type: string) => {
    return offers.filter(({ shDecision }) => shDecision[type]);
  };

  const filterByCategory = (categoryToFilter: string) => {
    if (categoryToFilter === offerCategory.ALL) {
      setFilteredOffers(offers);
      return offers;
    } else {
      const filtered = offers.filter(
        ({ content }) => content?.category === categoryToFilter
      );
      setFilteredOffers(filtered);
      return filtered;
    }
  };

  const numberOfOffers = (exception?: string) => {
    if (exception) {
      const offersWithExceptions = offers?.filter(
        ({ shDecision }) => !shDecision?.[exception]
      );

      return offersWithExceptions.length;
    } else {
      return offers.length;
    }
  };

  function redirectToOffers() {
    trackElementClicked({
      location: 'ac-minhas-dividas-destaque-ofertas',
      elementType: 'button',
      name: pageNames.offers.path,
      text: 'Ver ofertas',
    });
    navigate(pageNames.offers.path, { state: { key: offerCategory.ALL } });
  }

  function onCloseDigioModal() {
    setOpenDigioModal(false);
  }

  const onClickOffer = async (
    offer: OfferProps,
    location: string,
    index: number
  ) => {
    const { shDecision, content } = offer;

    const isDifferentFlow = await handleDifferentFlows({
      ...offer,
      location,
      index,
    });
    if (isDifferentFlow) return null;

    const proposalSoft = await sendProposal(
      shDecision.partner,
      shDecision.slug
    );
    const fireboltId = proposalSoft?.firebolt_id || '';
    trackProductClicked(offer, location, index, fireboltId);

    let url =
      proposalSoft?.webhookResult.redirectUrl ||
      (await getUrl(content?.links, fireboltId, shDecision?.preApproved));

    if (url) {
      await sendEventProposalStarted(offer.content, url, location, fireboltId);
      if (shDecision.slug === 'cards-neon') {
        return url && redirectToOfferNeon(offer, location, url, proposalSoft);
      }

      if (offer?.content?.full_funnel) {
        const userInfo: FireboltUserInfo = {
          cpf: user?.documento,
          fullName: user?.nome,
          email: user?.email,
          phone: user?.celular,
          birthdate: user?.dataNascimento,
        };

        url = addAutofillParam({
          productSlug: shDecision.slug,
          url,
          user: userInfo,
        });

        const isDev = import.meta.env.VITE_ENV === 'dev';
        if (isDev) {
          url = url.replace('https://www.', 'https://dev.');
        }
      }

      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltId);
    }
  };

  const handleDifferentFlows = async (
    offer: OfferProps & { location: string; index: number }
  ) => {
    const { shDecision, content, location, index } = offer;
    const url = await getUrl(content?.links, '', shDecision?.preApproved);

    switch (shDecision.slug) {
      case 'cards-digio':
        setOpenDigioModal(true);
        return true;
      case 'cards-bancodobrasil-ourocardfacil':
        if (url) {
          trackProductClicked(offer, location, index, '');
          trackRedirectRequested(offer, location, url, '');
          redirect(url);
        }
        return true;
    }
  };

  const redirectToOfferNeon = async (
    offer: OfferProps,
    location: string,
    url: string,
    fireboltInfo: any
  ) => {
    const redirectUrls = fireboltInfo?.webhookResult?.processedData as Partial<{
      storyblok_redirect_urls: { param: string; redirect: string }[];
    }>;
    const acOrganicUrl = redirectUrls.storyblok_redirect_urls?.find(
      (url) => url.param === 'ac_organic'
    );

    if (acOrganicUrl) {
      window.open(acOrganicUrl.redirect, '_blank');
      trackRedirectRequested(
        offer,
        location,
        acOrganicUrl.redirect,
        fireboltInfo?.firebolt_id
      );
    } else {
      redirect(url);
      trackRedirectRequested(offer, location, url, fireboltInfo?.firebolt_id);
    }
  };

  async function sendProposal(partnerSlug: string, productSlug: string) {
    const { customerIdHash } = user;
    const payload = {
      item: {
        cpf: user?.documento,
        full_name: user?.nome,
        email: user?.email,
        main_phone: user?.celular,
        date_of_birth: user?.dataNascimento,
        choosen_card: partnerSlug,
        issuer: partnerSlug,
        product_slug: productSlug,
      },
      metadata: {
        start_source_url: getCookie('start_source_url'),
        anonymousId: customerIdHash,
        sessionId: getSessionID(),
        fbCookies: {
          fcbId: getFbAvailableCookies().fbc,
          fbpId: getFbAvailableCookies().fbp,
        },
        gaCookies: {
          gaId: getGAAvailableCookies().ga,
        },
      },
    };

    try {
      const response = await sendSoftProposal.send(partnerSlug, payload);
      return response;
    } catch (error) {
      console.error('falha na requisição firebolt soft', error);
    }
  }

  const getUrl = (links: any,  fireboltId: string, isPreApproved?: boolean) => {
    const safeLinks = links || [];
    const linksAC = safeLinks.find(
      (link: any) => link.application === 'ac-links'
    );

    if (!linksAC) return;

    const preApprovedLink = userNegative
      ? linksAC?.link_pre_approved_negative
      : linksAC?.link_pre_approved;
    const defaultLink = userNegative
      ? linksAC?.link_with_debts
      : linksAC?.link_without_debts;
    const link = isPreApproved ? preApprovedLink : defaultLink;
    const urlWithParams = fillUrlParameters(link?.url, fireboltId);

    return urlWithParams;
  };

  const fillUrlParameters = async (url: string, fireboltId: string) => {
    const { customerIdHash } = user;
    const startSourceUrl = getCookie('start_source_url') || '';
    const deliveryId = getDeliveryIdParam({ startSourceUrl });
    const siteId =
      (await getSiteIdParam({
        marketplace: 'ac',
        startSourceUrl,
        getDatasource,
      })) || '';

    url = url
      .replace(/\(firebolt_id\)/g, fireboltId)
      .replace('(af_siteid)', siteId)
      .replace('(delivery_id)', deliveryId);
    if (url.includes('noverde')) {
      url = url.replace('(utm_term)', customerIdHash);
    }

    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    const filledParams: Record<string, string> = {
      anonymous_id: customerIdHash + Date.now(),
      session_id: customerIdHash + Date.now(),
      aff_unique2: customerIdHash,
      clickID: customerIdHash + 'T' + Date.now(),
      firebolt_id: fireboltId,
    };

    Object.keys(filledParams).forEach((chave) => {
      if (searchParams.has(chave)) {
        searchParams.set(chave, filledParams[chave]);
      }
    });

    return urlObj.toString();
  };

  const sendEventProductViewed = (
    offer: OfferProps = {} as OfferProps,
    index: number,
    location: string
  ) => {
    if (!offer || !offer.content || !offer.shDecision) return;

    const { content, shDecision } = offer;
    const { slug, title, category, partner, variant } = content;
    const { limit } = shDecision;

    const payload = {
      slug,
      category,
      variant,
      name: title,
      brand: partner,
      price: limit || 0,
      position: index,
      location: location,
    };

    trackProductViewed(payload);
  };

  const sendEventProposalStarted = async (
    contentOffer: OfferProps['content'],
    redirectUrl: string,
    location: string,
    fireboltId: string
  ) => {
    if (!contentOffer) return;
    const { slug, category } = contentOffer;

    if (contentOffer?.full_funnel) return;

    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category,
      redirectUrl,
      location,
      fireboltId,
    };
    await trackProposalStarted(payload);
  };

  const redirect = (url: string) => {
    if (isIOS() || isSafari()) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    filterByCategory(initialCategory);
  }, [offers]);

  useEffect(() => {
    if (decisionsList?.length > 0) {
      const offers = createOfferObjects(decisionsList);
      offers && setStateOffers(offers);
    }
  }, [decisionsList]);

  return {
    offers,
    featuredOffers,
    preApprovedOffers,
    userNegative,
    filterByCategory,
    filteredOffers,
    initialCategory,
    numberOfOffers,
    onClickOffer,
    redirectToOffers,
    onCloseDigioModal,
    openDigioModal,
    sendEventProductViewed,
    decideFinish,
    shLoading,
    noOffer,
  };
};
export default useGetOffers;
