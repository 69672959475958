import classNames from 'classnames';
import Button from '@libs/atlas/components/Button';
import Text from '@libs/atlas/components/Text';
import Conditional from '@libs/atlas/components/misc/Conditional';
import {
  IconCheck,
  IconCopy,
  IconPix,
} from '@libs/atlas/components/icons/default';
import Message from '@libs/atlas/components/Message';
import { PixProps } from '../../types';

import '../../styles.scss';

const Pix = ({
  pixCode,
  handleCopyPixCode,
  pixText,
  showUnavailablePixCode,
  showPixCode,
  isLoadingTryAgain,
  isPixLoading,
  isPixSuccess,
  isCodeCopied,
  handleGeneratePixCode,
}: PixProps) => {
  return (
    <>
      <Conditional
        condition={showPixCode}
        renderIf={
          <div className="payment-section__pix">
            <div className="payment-section__subheading">
              <IconPix />
              <Text>Código Pix</Text>
            </div>
            <Text as="p" weight="regular" className="payment-section__code">
              {pixCode}
            </Text>
            <Button
              className={classNames('payment-section__copy-code', {
                copied: isCodeCopied,
              })}
              expand="x"
              onClick={handleCopyPixCode}
              disabled={isPixLoading || isPixSuccess}
            >
              {isCodeCopied ? <IconCheck /> : <IconCopy iconSize="sm" />}
              {pixText}
            </Button>
          </div>
        }
      />
      <Conditional
        condition={showUnavailablePixCode}
        renderIf={
          <div className="payment-section__pix">
            <div className="payment-section__subheading">
              <IconPix />
              <Text>Código Pix</Text>
            </div>
            <Text as="p" weight="regular" className="payment-section__code">
              Código indisponível.
            </Text>
            <Message customClass="info-warning w-margin">
              <Text as="p" weight="regular">
                Parece que tivemos um pequeno problema ao gerar o seu código
                Pix, mas não se preocupe.{' '}
                <strong>Tente gerar o código novamente</strong>, caso ainda
                assim não consiga obter o código pix,{' '}
                <strong>por favor efetue o pagamento via boleto.</strong>
              </Text>
            </Message>
            <Button
              expand="x"
              type="secondary"
              disabled={isLoadingTryAgain}
              onClick={() => handleGeneratePixCode({ isTryAgain: true })}
            >
              Gerar código Pix novamente
            </Button>
          </div>
        }
      />
    </>
  );
};

export default Pix;
