import { useEffect, useState } from 'react';
import Item from './Item';
import { AccordionItemProps } from './types';

import './style.scss';

const Accordion = ({ items }: { items: AccordionItemProps[] }) => {
  const [openItems, setOpenItems] = useState<Record<number, boolean>>({});

  const toggleItem = (itemId: number) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  useEffect(() => {
    items.filter((item, index) => item.isOpen && toggleItem(index));
  }, []);

  return (
    <ul>
      {items.map((item, index) => (
        <Item
          hasChevron={item?.hasChevron}
          key={`${item?.content}-${item.title}`}
          title={item.title}
          isOpen={openItems[index] || false}
          onToggle={() => toggleItem(index)}
        >
          {item.content}
        </Item>
      ))}
    </ul>
  );
};

export default Accordion;
