import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useClosedAgreementExperiment = () => {
  const testCookieName = 'dx-closed-agreement-page';

  const VARIANTS = {
    A: 'old-payment-page',
    B: 'new-payment-page',
  };

  const currentResult = getCookie(testCookieName, false) || '';
  const resultIsNewPaymentPage = currentResult.includes(VARIANTS.B);

  return {
    resultIsNewPaymentPage,
  };
};

export default useClosedAgreementExperiment;
