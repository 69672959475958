import { COLOR_ERROR_50, IconMeh, Text } from '@consumidor-positivo/aurora';
import './styles.scss';
import classNames from 'classnames';
import { CSSProperties } from 'react';

type OfferEmptyStateProps = {
  style?: CSSProperties;
};

export const OfferEmptyState = ({ style }: OfferEmptyStateProps) => {
  const content = {
    title: 'Ops, algo deu errado e não encontramos ofertas no momento',
    desc: 'Já estamos trabalhando para resolver e pedimos que tente novamente mais tarde, por favor.',
  };

  return (
    <div className="offer-empty-state" style={style}>
      <IconMeh rawColor={COLOR_ERROR_50} />
      <div>
        <Text variant="body-medium" variantDesk="body-large" weight="bold">
          {content.title}
        </Text>
        <Text variant="body-small" variantDesk="body-medium">
          {content.desc}
        </Text>
      </div>
    </div>
  );
};
